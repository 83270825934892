<template>
  <div>
    <TheNavbar />
    <main>
      <div class="container-fluid p-0 me-0 ms-0">
        <div class="row">
          <div class="col-12 p-0">
            <transition
              enter-active-class="animate__animated animate__fadeIn"
              leave-active-class="animate__animated animate__fadeOut"
              mode="out-in"
              appear
              :key="$route.path"
              ><slot v-if="true"></slot></transition
            >
          </div>
        </div>
      </div>
    </main>
    
    <TheFooter   />
  </div>
</template>

<script>
import TheNavbar from "@/components/TheNavbar";
import TheFooter from "@/components/TheFooter";

export default {
  name: "Layout",
  components: {
    TheNavbar,
    TheFooter,
  },
};
</script>

<style scoped>
</style>