<template>
  <div class="card text-center textGrau" style="width: 802px">
    <div class="card-header">
      <h2 class="bg-vue2">Unternehmen / Philosophie</h2>
    </div>
    <div class="card-body" style="overflow:scroll; height:600px;">
      <p >
        Mit Gründung der Firma „Metall- und Lüftungsbau Holger Chemnitz“ im Mai
        1990 in Quittelsdorf war die Zielsetzung verbunden, lüftungstechnische
        Anlagen für Kunden im öffentlichen und gewerblichen Bereich zu
        installieren. Durch eigene Planung, Konstruktion und Fertigung
        bestimmter Bauteile, Baugruppen oder Anlagenkomponenten sollte eine
        große Flexibilität in Bezug auf Lieferzeit ermöglicht werden und
        gleichzeitig eine große Bandbreite an kundenorientierten, spezifischen
        Lösungen anzubieten. Auf Grund der kontinuierlichen Weiterentwicklung
        des Unternehmens erfolgte 2012 der Umzug in unseren Neubau am Standort
        Saalfeld Beulwitz.
      </p>
      <p >
        Heute können wir als eingetragener Fachbetrieb für Heizungs-und
        Klimatechnik sowie des Metallbauerhandwerkes auf Grund unserer
        langjährigen Erfahrung maßgeschneiderte Lösungen anbieten.
      </p>
      <p >
        Unser Ziel ist Qualität und Nachhaltigkeit. Kundenpartnerschaft und
        Kooperation ist für uns die Basis der geschäftlichen Beziehung. Sie
        bekommen bei uns Lösungen die genau auf Ihre Anforderungen und Wünsche
        zugeschnitten sind. Wir verknüpfen dabei jahrelange Erfahrung mit
        modernster Technologie. So fertigen wir auf CNC-gesteuerten Schneid- und
        Biegemaschinen und schweißen mit moderner Schweißtechnik Stahl-
        Edelstahl- sowie Aluminiumwerkstoffe.
      </p>
      <p>
        Innerhalb unseres Teams ist Partnerschaft und Mitgestaltung ein
        wichtiger Bestandteil unserer täglichen Arbeit. Ob in der Fertigung oder
        der Montage, die individuelle Stärke jedes Einzelnen nutzen und die
        gemeinsame zielorientierte Zusammenarbeit stärken ist tägliches Ziel.
      </p>
      <p>
        Die vier Grundsäulen unseres Erfolges sind Akzeptanz, Inspiration,
        Beteiligung und Zuverlässigkeit. Leistung fördern und fordern gehört zu
        unserer Unternehmenskultur ebenso wie der optimistische,
        verantwortungsvolle und von gegenseitigem Respekt geprägte Umgang
        miteinander. Durch die Verknüpfung dieser Prinzipien mit dem Wissen und
        der Erkenntnis vieler Jahre, gelingt es uns die Wünsche unserer Kunden
        zu erfüllen und zu übertreffen. Unsere Vision ist es unsere Kunden mit
        neuen Ideen voran zu bringen. Ihre Vorstellungen nicht nur zu erfüllen,
        sondern sie zu begeistern.
      </p>

      <button type="button" class="btn btn-danger" @click="close()">
        Schließen
      </button>
    </div>
    <!-- Modal footer -->
    <div class="modal-footer"></div>
  </div>
</template>
<script>
import { closeModal } from "jenesius-vue-modal";
export default {
  name: "ModalImpressum",
  methods: {
    close() {
      closeModal();
    },
  },
};
</script>
