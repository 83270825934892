<template>
  <Layout>
    <template #default>
      <div>
        <div class="container-fluid p-0">
          <div class="row  justify-content-center">
            <div class="col-md-6">
              
                <Transition appear name="bounce">
                 <h1 class="rotSgrauBig fw-bolder pb-4" style="text-align: center">
                    REFERENZEN
                  </h1>
                </Transition>
            </div>
          </div>

          <div class="row mb-0 bg-center grauDunkelfeld">
            <div class="col-md-12 grauDunkelfeld">
              <div
                id="ref-1"
                class="row ele3_1 feldweiss justify-content-center"
              >
                <div class="col-sm-10 col-md-8">
                  <h3 class="rotSgrau fw-bolder pt-5">
                    Fachhochschule Campus, Haus 3 in Jena
                  </h3>
                  <div class="row pt-4">
                    <div class="col-md-5 pt-1 ps-0">
                      <img
                        src="../assets/ref/Fachhochschule_1.jpg"
                        class="img-fluid d-block imageToBig"
                        @click="showImage(1)"
                      />
                    </div>
                    <div class="col-md-7">
                      <p>
                        Entrauchungsanlage /Zuluftanlage/Abluftanlage/Be- und
                        Entlüftungs- anlage mit Wärmerückgewinnung und Kühlung/
                        2 Stk druckgeregelte Anlagen mit variablen
                        Volumenströmen in Laboren / chemikalienbe- ständige
                        Lüftungsleitungen/EDV-Klimaanlage
                      </p>
                      <div class="row textLinksW">
                        <div class="col-7 ganzlinks">Lüftungsanlagen</div>
                        <div class="col-2 ganzlinks">14 Stk</div>
                        <div class="col-3 ganzlinks">104000 m³/h</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                id="ref-2"
                class="row pt-5 ele3_2 feldweiss justify-content-center"
              >
                <div class="col-sm-10 col-md-8">
                  <h3 class="rotSgrau fw-bolder pt-5">
                    Institut für Physikalische Hochtechnologie in Jena /
                    Beutenberg
                  </h3>
                  <div class="row pt-4">
                    <div class="col-md-5 pt-1 ps-0">
                      <img
                        src="../assets/ref/Fachhochschule_2.jpg"
                        class="img-fluid d-block imageToBig"
                        @click="showImage(2)"
                      />
                    </div>
                    <div class="col-md-7">
                      <p>
                        Zentralanlage ( Dachaufstellung für Be- und Entlüftung
                        von Laboren, mit Wärmerückgewinnung, Regelung erfolgt
                        unterdruck- abhängig in Verknüpfung der einzelnen Labore
                        (20 Stk) und deren Deckenflachgeräte für Kühlung und
                        Heizung, mit einzeln regelbarem Raumluftzustand / PPs-
                        Dauerabsaugung / PPs – Digestoren-Absaugung,
                        unterdruckgeregelt / vollklimatisierter Reinraum.
                      </p>
                      <div class="row textLinksW">
                        <div class="col-7 ganzlinks">Raumklimaanlage</div>
                        <div class="col-2">13 Stk</div>
                        <div class="col-3">3200 m³/h</div>
                      </div>
                      <div class="row textLinksW">
                        <div class="col-7 ganzlinks">Raumklimaanlage</div>
                        <div class="col-2">8 Stk</div>
                        <div class="col-3">2500 m³/h</div>
                      </div>
                      <div class="row textLinksW">
                        <div class="col-7 ganzlinks">PPs- Absaugung</div>
                        <div class="col-2">1 Stk</div>
                        <div class="col-3">2500 m³/h</div>
                      </div>
                      <div class="row textLinksW">
                        <div class="col-7 ganzlinks">PPs- Absaugung</div>
                        <div class="col-2">1 Stk</div>
                        <div class="col-3">1000 m³/h</div>
                      </div>
                      <div class="row textLinksW">
                        <div class="col-7 ganzlinks">Reinraumanlage</div>
                        <div class="col-2">1 Stk</div>
                        <div class="col-3">4000 m³/h</div>
                      </div>
                      <div class="row textLinksW">
                        <div class="col-7 ganzlinks">
                          Zentrale Be- und Entlüftungsanlage
                        </div>
                        <div class="col-2">1 Stk</div>
                        <div class="col-3">11000 m³/h</div>
                      </div>
                      <div class="row textLinksW">
                         <div class="col-7 ganzlinks"></div>
                        <div class="col-2"></div>
                        <div class="col-3"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                id="ref-3"
                class="row pt-5 ele3_3 feldweiss justify-content-center"
              >
                <div class="col-sm-10 col-md-8">
                  <h3 class="rotSgrau fw-bolder pt-5">
                    Stadthalle Bad Blankenburg
                  </h3>
                  <div class="row pt-4">
                    <div class="col-md-5 pt-1 ps-0">
                      <img
                        src="../assets/ref/Stadthalle.jpg"
                        height="150"
                        class="img-fluid d-block imageToBig"
                        @click="showImage(3)"
                      />
                    </div>
                    <div class="col-md-7">
                      <p>
                        Zentrale Lüftungsanlagen mit Wärmerückgewinnung mittels
                        KV-System oder Plattenwärmetauscher, Kühlung mittels
                        Kaltwasser, Umluftkühlgeräte, Küchenbe- und Entlüftung
                        mit Energiesparhauben durch Außenluftzuführung mit
                        Außentemperatur - Induktionshauben
                      </p>
                      <div class="row  textLinksW">
                         <div class="col-7 ganzlinks">Be- und Entlüftungsanlage Saal</div>
                        <div class="col-2">1 Stk</div>
                        <div class="col-3">60000 m³/h</div>
                      </div>
                      <div class="row textLinksW">
                         <div class="col-7 ganzlinks">
                          Be- und Entlüftungsanlage Club/Konferenzraum
                        </div>
                        <div class="col-2">2 Stk</div>
                        <div class="col-3">6000 m³/h</div>
                      </div>
                      <div class="row textLinksW">
                         <div class="col-7 ganzlinks">Be- und Entlüftungsanlage Foyer</div>
                        <div class="col-2">1 Stk</div>
                        <div class="col-3">5000 m³/h</div>
                      </div>
                      <div class="row textLinksW">
                         <div class="col-7 ganzlinks">
                          Be- und Entlüftungsanlage Bistro
                        </div>
                        <div class="col-2">1 Stk</div>
                        <div class="col-3">2500 m³/h</div>
                      </div>
                      <div class="row textLinksW">
                         <div class="col-7 ganzlinks">
                          Be- und Entlüftungsanlage Küche Saal/Bistro
                        </div>
                        <div class="col-2">2 Stk</div>
                        <div class="col-3">10800 m³/h</div>
                      </div>
                      <div class="row textLinksW">
                         <div class="col-7 ganzlinks">Entlüftung Sanitärräume</div>
                        <div class="col-2">3 Stk</div>
                        <div class="col-3">700 m³/h</div>
                      </div>
                      <div class="row textLinksW">
                         <div class="col-7 ganzlinks">Umluftkühler ELT-Räume</div>
                        <div class="col-2">2 Stk</div>
                        <div class="col-3">5000 m³/h</div>
                      </div>
                      <div class="row textLinksW">
                         <div class="col-7 ganzlinks">Umluftkühler Eingangsbereich</div>
                        <div class="col-2">8 Stk</div>
                        <div class="col-3">16000 m³/h</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                id="ref-4"
                class="row pt-5 ele3_4 feldweiss justify-content-center"
              >
                <div class="col-sm-10 col-md-8">
                  <h3 class="rotSgrau fw-bolder pt-5">
                    Friedrich Schiller Universität Jena
                  </h3>
                  <div class="row pt-4">
                    <div class="col-md-5 pt-1 ps-0">
                      <img
                        src="../assets/ref/Frei_1.jpg"
                        height="150"
                        class="img-fluid d-block imageToBig"
                        @click="showImage(4)"
                      />
                    </div>
                    <div class="col-md-7">
                      <p>
                        KMT-Labor : Reinraumanlage mit Luftqualität entsprechend
                        GMP-Richtlinie. Reinraumklasse A und B. Lüftungsanlage
                        mit vierstufiger Filterkaskade, Kühlung, Entfeuchtung,
                        Druckkonstanthaltung unter Berücksichtigung der
                        Filterverschmutzung und Einhaltung der Druckdifferenzen
                        der einzelnen Arbeitsbereiche (Schleusenfunktion).
                      </p>
                      <div class="row textLinksW">
                         <div class="col-7 ganzlinks">Volumenstrom</div>
                        <div class="col-2"></div>
                        <div class="col-3">4000m³/h.</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                id="ref-5"
                class="row pt-5 ele3_5 feldweiss justify-content-center"
              >
                <div class="col-sm-10 col-md-8">
                  <h3 class="rotSgrau fw-bolder pt-5">
                    Anna Amalia Bibliothek Weimar
                  </h3>
                  <div class="row pt-4">
                    <div class="col-md-5 pt-1 ps-0">
                      <img
                        src="../assets/ref/AnnaAmalia.jpg"
                        height="150"
                        class="img-fluid d-block imageToBig"
                        @click="showImage(5)"
                      />
                    </div>
                    <div class="col-md-7">
                      <p>
                        Sanierung nach Brandschaden: Lüftungsanlage zur
                        Raumklimatisierung, kontrollierte Austrocknung der
                        Bausubstanz auf Grund des Wasserschadens als Folge der
                        Löscharbeiten. Zentrale Lüftungsanlage mit Entfeuchtung,
                        Nacherhitzer, Außenluftbeimischung,
                        Volumenstromregelung, Steuergröße ist Raumfeuchte und
                        Temperatur in einzelnen Bereichen /Denkmalgeschütztes
                        Gebäude
                      </p>
                      <div class="row textLinksW">
                         <div class="col-7 ganzlinks">Anlagengröße</div>
                        <div class="col-2"></div>
                        <div class="col-3">8000m³ /h</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                id="ref-6"
                class="row pt-5 ele3_6 feldweiss justify-content-center"
              >
                <div class="col-sm-10 col-md-8">
                  <h3 class="rotSgrau fw-bolder pt-5">
                    Aeropharm GmbH Rudolstadt
                  </h3>
                  <div class="row pt-4">
                    <div class="col-md-5 pt-1 ps-0">
                      <img
                        src="../assets/ref/Aeropharm_1.jpg"
                        height="150"
                        class="img-fluid d-block imageToBig"
                        @click="showImage(6)"
                      />
                    </div>
                    <div class="col-md-7">
                      <p>
                        Neubau eines Fertigungsgebäudes und eines
                        Hochregallagers. Zentrale Lüftungsanlagen mit
                        Wärmerückgewinnung mittels KV-System und
                        Plattenwärmetauscher, Kühlung mittels Kaltwasser,
                        Hygienegeräte, Anlage Fertigung: Ausführung für
                        Reinraumklasse D
                      </p>
                      <div class="row textLinksW">
                         <div class="col-7 ganzlinks">Klimatisierung Fertigung</div>
                        <div class="col-2"></div>
                        <div class="col-3">33500 m³/h</div>
                      </div>
                      <div class="row textLinksW">
                         <div class="col-7 ganzlinks">
                          Be- und Entlüftungsanlage Hochregallager
                        </div>
                        <div class="col-2"></div>
                        <div class="col-3">13500 m³/h</div>
                      </div>
                      <div class="row textLinksW">
                         <div class="col-7 ganzlinks">
                          Be- und Entlüftung Verbindungsgang
                        </div>
                        <div class="col-2"></div>
                        <div class="col-3">1100 m³/h</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                id="ref-7"
                class="row pt-5 ele3_7 feldweiss justify-content-center"
              >
                <div class="col-sm-10 col-md-8">
                  <h3 class="rotSgrau fw-bolder pt-5">
                    Formenwerkstatt Glaswerk Behälterglas Thüringen
                  </h3>
                  <div class="row pt-4">
                    <div class="col-md-5 pt-1 ps-0">
                      <img
                        src="../assets/ref/Formenwerkstatt.jpg"
                        height="150"
                        class="img-fluid d-block imageToBig"
                        @click="showImage(7)"
                      />
                      <h5 class="pt-4">* Eigenplanung *</h5>
                    </div>
                    <div class="col-md-7">
                      <p>
                        Umluftanlage mit Außenluftbeimischung als
                        Überdruckanlage, Abluft - und Zuluft für 14 Schleif- und
                        Schweißarbeitsplätze sowie acht Werkzeugmaschinen.
                        Anlage mit Wärmerückgewinnung, automatischer
                        Filterabreinigung sowie bedarfsgerechter, automatischer
                        Volumenstromregelung.
                      </p>
                      <div class="row textLinksW">
                         <div class="col-7 ganzlinks">Sandstrahlkabinenabluftanlage</div>
                        <div class="col-2">1 Stk</div>
                        <div class="col-3"></div>
                      </div>
                      <div class="row textLinksW">
                         <div class="col-7 ganzlinks">
                          Lackierarbeitsplatz Be- und Entlüftung
                        </div>
                        <div class="col-2">1 Stk</div>
                        <div class="col-3"></div>
                      </div>
                      <div class="row textLinksW">
                         <div class="col-7 ganzlinks">
                          Umluftheizung mit bedarfsgerechter und
                          feuchteabhängiger Außenluftregelung
                        </div>
                        <div class="col-2">1 Stk</div>
                        <div class="col-3"></div>
                      </div>
                      <div class="row textLinksW">
                         <div class="col-7 ganzlinks">
                          Abluftanlage für Glühofen mit Wärmerückgewinnung und
                          Luftrückführung
                        </div>
                        <div class="col-2">1 Stk</div>
                        <div class="col-3"></div>
                      </div>
                      <div class="row textLinksW">
                         <div class="col-7 ganzlinks">Gesamtvolumenstrom</div>
                        <div class="col-3">ca. 25000 m³/h</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                id="ref-8"
                class="row pt-5 ele3_8 feldweiss justify-content-center"
              >
                <div class="col-sm-10 col-md-8">
                  <h3 class="rotSgrau fw-bolder pt-5">
                    Aeropharm Rudolstadt GmbH
                  </h3>
                  <div class="row pt-4">
                    <div class="col-md-5 pt-1 ps-0">
                      <img
                        src="../assets/ref/Aeropharm_2.jpg"
                        height="150"
                        class="img-fluid d-block imageToBig"
                        @click="showImage(8)"
                      />
                      <h5 class="pt-4">* Eigenplanung *</h5>
                    </div>
                    <div class="col-md-7">
                      <p>
                        Labore Dry Powder sowie Erweiterung Labore; Zentrale
                        Lüftungsanlage Vollklimatisiert, Kühlung mittels
                        Kaltwasser, Erhitzer PWW, Befeuchtung mittels
                        Dampfbefeuchter, Entfeuchtung und Nacherhitzen,
                        Druckregelung zur Vermeidung Kontamination sowie als
                        Ausgleich für dezentrale Abluftanlagen, Umluftkühler und
                        Nacherhitzer in allen Räumen (24 Räume) Laborabluft
                        teilweise dezentral mit Kunststoffluftleitungen,
                        druckgeregelt
                      </p>
                      <div class="row textLinksW">
                         <div class="col-7 ganzlinks">Be- und Entlüftung Labore</div>
                        <div class="col-2"></div>
                        <div class="col-3">19500 m³/h</div>
                      </div>
                      <div class="row textLinksW">
                         <div class="col-7 ganzlinks">Abluft Labore</div>
                        <div class="col-2"></div>
                        <div class="col-3">4000 m³/h</div>
                      </div>
                      <p class="pt-5">
                        Sterile Liquida / Zentrale Lüftungsanlage
                        Teilklimatisiert, Kühlung mittels Kaltwasser, Erhitzer
                        PWW, Druckregelung zur Vermeidung von Kontamination
                        sowie als Ausgleich für dezentrale Abluftanlagen,
                        Reinraumklasse C/D und E Laborabluft teilweise dezentral
                        mit Kunststoffluftleitungen druckgeregelt
                      </p>
                      <div class="row textLinksW">
                         <div class="col-7 ganzlinks">Be- und Entlüftung</div>
                        <div class="col-2"></div>
                        <div class="col-3">4 000 m³/h</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                id="ref-9"
                class="row pt-5 ele3_9 feldweiss justify-content-center"
              >
                <div class="col-sm-10 col-md-8">
                  <h3 class="rotSgrau fw-bolder pt-5">
                    Kennametal Königsee GmbH
                  </h3>
                  <div class="row pt-4">
                    <div class="col-md-5 pt-1 ps-0">
                      <img
                        src="../assets/ref/Kennametal.jpg"
                        height="150"
                        class="img-fluid d-block imageToBig"
                        @click="showImage(9)"
                      />
                      <h5 class="pt-4">* Eigenplanung *</h5>
                    </div>
                    <div class="col-md-7">
                      <p>
                        Abluft KSEM, Nachschleifservice Abluftanlagen für
                        CNC-Präzisions-Schleifautomaten mit Aerosolabscheider
                        und elektrostatischen Abscheider, Kunststoffleitungen
                        geschweißt, Volumenstrom-Konstanthaltung mittels Druck-
                        und Volumenstromreglern, automatischer Löscheinrichtung
                      </p>
                      <div class="row textLinksW">
                         <div class="col-7 ganzlinks">Abluftanlagen</div>
                        <div class="col-2">8 Stk</div>
                        <div class="col-3">80 000 m³/h</div>
                      </div>
                      <div class="row textLinksW">
                         <div class="col-7 ganzlinks"></div>
                        <div class="col-2">1 Stk</div>
                        <div class="col-3">6 000 m³/h</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                id="ref-10"
                class="row pt-5 ele3_10 feldweiss justify-content-center"
              >
                <div class="col-sm-10 col-md-8">
                  <h3 class="rotSgrau fw-bolder pt-5">
                    Thüringisches Institut für Textil- und Kunststoff-Forschung
                    Rudolstadt
                  </h3>
                  <div class="row pt-4">
                    <div class="col-md-5 pt-1 ps-0">
                      <img
                        src="../assets/ref/TITK.jpg"
                        height="150"
                        class="img-fluid d-block imageToBig"
                        @click="showImage(10)"
                      />
                      <h5 class="pt-4">* Eigenplanung *</h5>
                    </div>
                    <div class="col-md-7">
                      <p>
                        Reinraumanlage Spritzgussanlage /Zentrale Lüftungsanlage
                        zur Be- und Entlüftung des Fertigungsprozesses,
                        Druckregelung zur Vermeidung von Kontamination zwischen
                        Fertigung und Verpackung, Reinraumklasse D und E
                        Lüftungsanlage mit Zonenregelung und
                        Filterdiffernzdruckausgleich,
                      </p>
                      <div class="row textLinksW">
                         <div class="col-7 ganzlinks">Be- und Entlüftung</div>
                        <div class="col-2"></div>
                        <div class="col-3">4 000 m³/h</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                id="ref-11"
                class="row pt-5 ele3_11 feldweiss justify-content-center"
              >
                <div class="col-sm-10 col-md-8">
                  <h3 class="rotSgrau fw-bolder pt-5">
                    Behälterglas Schleusingen Fertigungslinie II
                  </h3>
                  <div class="row pt-4">
                    <div class="col-md-5 pt-1 ps-0">
                      <img
                        src="../assets/ref/FertigungslinieII.jpg"
                        height="150"
                        class="img-fluid d-block imageToBig"
                        @click="showImage(11)"
                      />
                      <h5 class="pt-4">* Eigenplanung *</h5>
                    </div>
                    <div class="col-md-7">
                      <p>
                        Lüftungsanlage zur Temperierung sowie Be- und Entlüftung
                        des Fertigungsbereiches Abkühlstrecke mit
                        Wärmerückgewinnung und ohne Nacherhitzung / hohe
                        thermische Belastung
                      </p>
                      <div class="row textLinksW">
                         <div class="col-7 ganzlinks">Be- und Entlüftung</div>
                        <div class="col-2"></div>
                        <div class="col-3">25000 m³/h</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                id="ref-12"
                class="row pt-5 ele3_12 feldweiss justify-content-center"
              >
                <div class="col-sm-10 col-md-8">
                  <h3 class="rotSgrau fw-bolder pt-5">
                    KATCN Universität Aachen / RWTH Aachen University
                  </h3>
                  <div class="row pt-4">
                    <div class="col-md-5 pt-1 ps-0">
                      <img
                        src="../assets/ref/RWTH.jpg"
                        height="150"
                        class="img-fluid d-block imageToBig"
                        @click="showImage(12)"
                      />
                      <h5 class="pt-4">* Eigenplanung *</h5>
                    </div>
                    <div class="col-md-7">
                      <p>
                        Sauberraum-Anlage mit Druckzonenregelung für Weiß- und
                        Schwarzbereich, mit integrierter technologischer Abluft,
                        automatische Volumenstromreglung vor einzelne
                        Bearbeitungszonen, Raumtemperaturführung des
                        Gesamtvolumenstromes
                      </p>
                      <div class="row textLinksW">
                         <div class="col-7 ganzlinks">Be- und Entlüftung</div>
                        <div class="col-2"></div>
                        <div class="col-3">6000 m³/h</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                id="ref-13"
                class="row pt-5 ele3_13 feldweiss justify-content-center"
              >
                <div class="col-sm-10 col-md-8">
                  <h3 class="rotSgrau fw-bolder pt-5">
                    Hospital Helsingborg / Schweden
                  </h3>
                  <div class="row pt-4">
                    <div class="col-md-5 pt-1 ps-0">
                      <img
                        src="../assets/ref/Helsingborg_1.jpg"
                        height="150"
                        class="img-fluid d-block imageToBig"
                        @click="showImage(13)"
                      />
                    </div>
                    <div class="col-md-7">
                      <p>
                        Neubau eines Klinikgebäudes Zentrale Lüftungsanlagen
                        Wärmerückgewinnung mittels Rotationswärmetauscher,
                        Kühlung mittels Kaltwasser, Hygienegeräte
                      </p>
                      <div class="row textLinksW">
                         <div class="col-7 ganzlinks">Gebäudekomplex 1 bis 3</div>
                        <div class="col-2"></div>
                        <div class="col-3">16500 m³/h</div>
                      </div>
                      <div class="row textLinksW">
                         <div class="col-7 ganzlinks">Gebäudekomplex 2 bis 4</div>
                        <div class="col-2"></div>
                        <div class="col-3">17000 m³/h</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                id="ref-14"
                class="row pt-5 ele3_14 feldweiss justify-content-center"
              >
                <div class="col-sm-10 col-md-8">
                  <h3 class="rotSgrau fw-bolder pt-5">
                    Reinraum Chipfertigung Vishay Elektronik GmbH
                  </h3>
                 <div class="row pt-4">
                    <div class="col-md-5 pt-1 ps-0">
                      <img
                        src="../assets/ref/Vishay_1.jpg"
                        height="150"
                        class="img-fluid d-block imageToBig"
                        @click="showImage(14)"
                      />
                      <h5 class="pt-4">* Eigenplanung *</h5>
                    </div>
                    <div class="col-md-7">
                      <p>
                        Zentrale Lüftungsanlage zur Be- und Entlüftung sowie
                        Klimatisierung des Fertigungsprozesses mit
                        Druckzonenregelung für Weiß- und Schwarzbereich, mit
                        integrierter technologischer Abluft, automatische
                        Volumenstromreglung
                      </p>
                      <div class="row textLinksW">
                         <div class="col-7 ganzlinks">
                          Klimatisierung / Be- und Entlüftung
                        </div>
                        <div class="col-2">je</div>
                        <div class="col-3">15.000m³/h</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                id="ref-15"
                class="row pt-5 ele3_15 feldweiss justify-content-center"
              >
                <div class="col-sm-10 col-md-8">
                  <h3 class="rotSgrau fw-bolder pt-5">
                    Klimatisierung Schaltwarte BASF Pervormance GmbH Schwarza
                  </h3>
                  <div class="row pt-4">
                    <div class="col-md-5 pt-1 ps-0">
                      <img
                        src="../assets/ref/BASF_1.jpg"
                        height="150"
                        class="img-fluid d-block imageToBig"
                        @click="showImage(15)"
                      />
                    </div>
                    <div class="col-md-7">
                      <p>
                        Klimatisierung Schaltwarte NSHV mit gleitender
                        Außenluftkühlung und Druckhaltung
                      </p>
                      <div class="row textLinksW">
                         <div class="col-7 ganzlinks">Klimatisierung</div>
                        <div class="col-2"></div>
                        <div class="col-3">14.000m³/h</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                id="ref-16"
                class="row pt-5 ele3_16 feldweiss justify-content-center"
              >
                <div class="col-sm-10 col-md-8">
                  <h3 class="rotSgrau fw-bolder pt-5">
                    Neubau Recyklatanlage (Kunststoffgranualt) PET
                    Großbreitenbach
                  </h3>
                  <div class="row pt-4">
                    <div class="col-md-5 pt-1 ps-0">
                      <img
                        src="../assets/ref/PET.jpg"
                        height="150"
                        class="img-fluid d-block imageToBig"
                        @click="showImage(16)"
                      />
                    </div>
                    <div class="col-md-7">
                      <p>
                        Be- und Entlüftung Produktion-, Umkleide- und
                        Sozialräume-, Kältemaschinenraum, Druckluftzentrale,
                        Labor und Serverraum, Klimatisierung NHSV
                        Schaltanlagenraum 8 Anlagen
                      </p>
                      <div class="row textLinksW">
                         <div class="col-7 ganzlinks">Be- und Entlüftung</div>
                        <div class="col-2"></div>
                        <div class="col-3">28.000m³/h</div>
                      </div>
                      <div class="row textLinksW">
                         <div class="col-7 ganzlinks">Klimatisierung</div>
                        <div class="col-2"></div>
                        <div class="col-3">9.000m³/h</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                id="ref-17"
                class="row pt-5 ele3_17 feldweiss justify-content-center"
              >
                <div class="col-sm-10 col-md-8">
                  <h3 class="rotSgrau fw-bolder pt-5">
                    Neubau Fertigungslinie UV-Speziallampen Wümbach
                  </h3>
                  <div class="row pt-4">
                    <div class="col-md-5 pt-1 ps-0">
                      <img
                        src="../assets/ref/UV-Technik.jpg"
                        height="150"
                        class="img-fluid d-block imageToBig"
                        @click="showImage(17)"
                      />
                    </div>
                    <div class="col-md-7">
                      <p>
                        Be- und Entlüftungsanlagen Produktionsräume mit
                        bedarfsabhängiger Druckzonenregelung, Be- und
                        Entlüftungsanlage Sozial- und Büroräume, drei lokale
                        Entlüftungsanlagen
                      </p>
                      <div class="row textLinksW">
                         <div class="col-7 ganzlinks">Be- und Entlüftung</div>
                        <div class="col-2"></div>
                        <div class="col-3">32.000m³/h</div>
                      </div>
                      <div class="row textLinksW">
                         <div class="col-7 ganzlinks">Technolog. Abluft</div>
                        <div class="col-2"></div>
                        <div class="col-3">6.000m³/h</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Layout>
</template>

<script>
import Layout from "@/layouts/Layout";
import { openModal } from "jenesius-vue-modal";
import ModalsImageDisplay from "../components/ModalsImageDisplay.vue";

export default {
  name: "AktuellesRef",

  data() {
    return {
      aktuelleSite: "bewe",
      show: false,
    };
  },
  components: {
    Layout,
  },
  methods: {
    async showImage(Imagenumber) {
      const modal = await openModal(ModalsImageDisplay, {
        Imagenumber: Imagenumber,
      });
      modal.onclose = () => {
        console.log("Close Modal gefunden");

        return true; //Modal will not be closed
      };
    },
  },
  computed: {},
  created() {
    this.show = true;
  },
};
</script>

<style scoped></style>
