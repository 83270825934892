<template>
  <Layout>
    <template #default>
      <div>
        <div class="container-fluid p-0">
          <div class="row justify-content-center">
            <div class="col-md-8 ">
                <Transition appear name="bounce">
                  <h1 class="rotSgrauBig fw-bolder pb-4" style="text-align: center">KARRIERE</h1>                        
                </Transition>
              </div>
          </div>
       
          <div class="row  mb-0 bg-center" id="top">
            <div class="col-md-12 p-0">
              
              <div class="row">
                <div class="col-12  fw-bold text-center rot p-0">
                  <img src="../assets/Karriere_Chemnitz1.jpg" style="width: 100%;" p-0 />
                </div>
              </div>
            


              <div class="row">
                <a href="#t0" class="sticky">
                  <i class='fas fa-angle-double-up' style='font-size:36px'></i>
                </a>
                <div class="row mt-3 mb-3 justify-content-center">
                  <div class="col-sm-10  col-md-8  pt-3  ">
                  <h4 class="pb-5">
                    Wir sind ein familiengeführtes Metallbauunternehmen 
                  </h4>
                  <p class="pb-1">
                  <b>Unseren Mitarbeitern bieten wir:</b></p>
                  <ul class="ps-3">
                    <li>abwechslungsreiche und anspruchsvolle Tätigkeit</li>
                    <li>ein unbefristetes Arbeitsverhältnis</li>
                    <li>leistungsgerechte Vergütung</li>
                    <li>Sicheres monatliches Einkommen</li>
                    <li>kostenfreie arbeitsmedizinische Vorsorgeuntersuchung</li>
                    <li>kostenfreie Weiterbildungsmöglichkeit</li>
                    <li>kostenfreie Stellung von Arbeitskleidung</li>
                    <li>ein freundliches Betriebsklima, geprägt von Vertrauen, Respekt und Fairness</li>
                    <li>ein interessantes und abwechslungsreiches Aufgabengebiet</li>
                    <li>Übernahme der Kosten für Unterkunft bei überregionalen Einsätzen</li>
                    <li>kostenfreie Bereitstellung von Werkzeug und Ausrüstung</li>
                  </ul>
                  </div>
                </div>
              
              <div class="row mt-3 justify-content-center">
                  <div class="col-sm-10  col-md-8  pt-3  ">
                  <h4>und bieten ab sofort folgende Stellenangebote:</h4>
                  </div>
                </div>
              </div>

              
              <div class="row mt-3 mb-5 justify-content-center">
                <div class="col-sm-10  col-md-8  pt-3  border border-3">
                  <h4>eine/n Mitarbeiter/in der Endfertigung.</h4>
                  <p>
                    Das primäre Stellenziel besteht in der fachgerechten, qualitätsgerechten und den Anforderungen entsprechenden Produktion.
                    </p>
                    <p>
                      Zu Ihren Aufgaben gehören unter anderem folgende Tätigkeiten.<br />
                      <ul>
                        <li>
                          Zeichnungen und Pläne lesen und umsetzen
                        </li>
                        <li>
                          Bedienung der entsprechenden Produktionsmaschinen laut Plan mit Nutzung von Vorrichtungen, Werkzeugen, Mess- und Prüfmitteln sowie aller notweniger Hilfsmittel
                        </li>
                        <li>
                          Einhaltung der vereinbarten Liefertermine
                        </li>
                        <li>
                          Durchführung von Sichtprüfungen und notwenigen Maßprüfungen
                        </li>
                        <li>
                          Durchführung von Qualitätsprüfungen in der laufenden Produktion nach Maßgabe des QM-Systems (Werkerselbstprüfung)
                        </li>
                        <li>
                          Sicherstellung von anforderungsgerechter Fertigung der Produkte
                        </li>
                        <li>
                          Durchführung und Aufzeichnung von Wartungen in Absprache
                        </li>
                      </ul>
                      ... und viele weitere interessante und abwechslungsreiche fachspezifischen Tätigkeiten
                    </p>
                    <p>
                      Wir erwarten: 
                    </p>
                    <p>
                      Abgeschlossene Berufsausbildung oder mehrjährige Berufserfahrung oder motivierte und qualitätsorientierte Arbeitsweise
                    </p>
                    <p>
                    <br />
                    Wenn wir Ihr Interesse geweckt haben, melden Sie sich
                    telefonisch oder schriftlich bei uns.<br />
                    <b>Sie können auch gerne unsere <a href="#onlinebewerbung" class="h5AnkerLink">Onlinebewerbung</a> nutzen</b><br /><br />
                    Wir vereinbaren dann ein persönliches Gespräch zum
                    Kennenlernen.
                  </p>
                </div>
              </div>



              <div class="row mt-5 mb-5 justify-content-center">
                <div class="col-sm-10  col-md-8  pt-3  border border-3">
                  <h4>Engagierte und motivierte Anlagenmechaniker (m/w)</h4>
                  <p>
                    Zur Verstärkung unseres Teams suchen wir engagierte und motivierte Anlagenmechaniker (m/w) für Heizungs-, Klima- und Lüftungstechnik

                    </p>
                    <p>
                      Zu Ihren Aufgaben gehören unter anderem folgende Tätigkeiten.<br />
                      <ul>
                        <li>
                          Umbau und Neubau von RLT-Anlagen, Klimaanlagen und prozessluftechnischen Anlagen 
                        </li>
                        <li>
                          Montage, Wartung und Instandsetzung von Lüftungsanlagen
                        </li>
                        <li>
                          Inbetriebnahme von neuen Anlagen
                        </li>
                        <li>
                          Protokollieren der durchgeführten Arbeiten
                        </li>
                        <li>
                          Fehlersuche und Instandsetzungsarbeiten an Lüftungs- und Klimaanlagen im Rahmen von Störungsmeldungen
                        </li>
                        <li>
                          Arbeiten auf Leitern, Gerüsten und Arbeitsbühnen
                        </li>
                        
                      </ul>
                      ... und viele weitere interessante und abwechslungsreiche fachspezifischen Tätigkeiten
                    </p>
                    <p>
                      Wir erwarten: 
                    </p>
                    <p>
                      <ul>
                        <li>eine abgeschlossene Ausbildung als Anlagenmechaniker für Heizungs-, Klima- und Lüftungstechnik oder eine vergleichbare Berufsausbildung</li>
                        <li>erste Berufserfahrungen im beschriebenen Aufgabengebiet</li>
                        <li>einen Führerschein (Klasse B bzw. 3), um mit dem Firmenwagen die Einsatzorte zu erreichen</li>
                        <li>Zertifikat VDI 6022 (von Vorteil)</li>
                        <li>Bereitschaft zur gelegentlichen Montage Deutschlandweit</li>
                        <li>Zuverlässigkeit, Motivation und Teamfähigkeit</li>
                      </ul>
                      Abgeschlossene Berufsausbildung oder mehrjährige Berufserfahrung oder motivierte und qualitätsorientierte Arbeitsweise
                    </p>
                    <p>
                    <br />
                    Wenn wir Ihr Interesse geweckt haben, melden Sie sich
                    telefonisch oder schriftlich bei uns.<br />
                    <b>Sie können auch gerne unsere <a href="#onlinebewerbung" class="h5AnkerLink">Onlinebewerbung</a>
                      nutzen</b><br /><br />
                    Wir vereinbaren dann ein persönliches Gespräch zum
                    Kennenlernen.
                  </p>
                </div>
              </div>
              

              <div class="row mt-3 mb-5 justify-content-center">
                <div class="col-sm-10  col-md-8  pt-3  border border-3">
                  <h4>Sie sind Berufseinsteiger?</h4>
                  <p class="pt-4">
                    Arbeiten mit Metall faszienieren Sie? Es macht Sie stolz etwas herstellen zu können?
                    </p>
                    <p>
                     Die hier gelesenen Tätigkeiten sind genau das, was Sie gerne machen möchten?
                    </p>
                    <p>
                      Sie haben eine motivierte und qualitätsorientierte Arbeitsweise und sind neugierig etwas zu lernen?
                    </p>
                    <p>
                     Dann sollten wir uns unbedingt einmal kennen lernen.
                    </p>
                    <p>
                    <br />                   
                    <b>Sie können gerne unsere <a href="#onlinebewerbung" class="h5AnkerLink">Onlinebewerbung</a> nutzen</b><br /><br />
                    Wir vereinbaren dann ein persönliches Gespräch zum
                    Kennenlernen.
                  </p>
                </div>
              </div>
            </div>
          </div>
          
          <div class="row pt-5 pb-5 mb-0 grauDunkelfeld justify-content-center" id="onlinebewerbung">
           
            <div class="col-md-8 feldweiss"  id="be">
              <div class="row">
                <div class="col-12">
                  <div class="title">
                    <h1 class="rotSgrauBig fw-bolder pb-4" style="text-align: center">Online Bewerbung</h1>
                    <h5 style="text-align: center">
                      Wir freuen uns auf Ihre Bewerbung
                    </h5>
                  </div>
                </div>
              </div>

              <div class="row justify-content-center">
                <div class="col-md-10 ">
                  <div class="titleKlein">
                    <h5>Persönliches</h5>
                  </div>
                </div>
              </div>
              <div class="row mb-3 justify-content-center">
                <div class="col-md-10 ">
                  <label for="job" class="form-label">Stelle</label>
                  <select
                    class="form-select"
                    aria-label="Default select example"
                    id="job"
                    v-model="bewerbung.Stelle"
                  >
                    <option value="nicht gewählt" selected>Bitte auswählen</option>
                    <option value="Metallbauer Lüftungsbau">Metallbauer Lüftungsbau</option>
                    <option value="Servicemechaniker">Servicemechaniker</option>
                    <option value="Auszubildende*r Büro">Auszubildende*r Büro</option>
                    <option value="Auszubildende*r Lüftungsbau">Auszubildende*r Lüftungsbau</option>
                  </select>
                  
                </div>
              </div>
              <div class="row mb-3 justify-content-center">
                <div class="col-md-5 ">
                  <label for="anrede" class="form-label">Anrede</label>
                  <select
                    class="form-select"
                    aria-label="Default select example"
                    v-model="bewerbung.Anrede"
                  >
                    <option value="ohne" selected>Bitte auswählen</option>
                    <option value="Frau">Frau</option>
                    <option value="Herr">Herr</option>
                    <option value="Neutrale Anrede">Neutrale Anrede</option>
                  </select>
                  
                </div>
                <div class="col-md-5">
                  <label for="titel" class="form-label"
                    >Titel (Dr., Prof.)</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="titel"
                    v-model="bewerbung.Titel"
                  />
                </div>
              </div>
              <div class="row mb-3  justify-content-center">
                <div class="col-md-5 ">
                  <label for="vname" class="form-label">Vorname*</label>
                  <input
                    type="text"
                    class="form-control"
                    id="vname"
                    v-model="bewerbung.Vorname"
                    v-bind:class="[error.Vorname == true ? 'bg-warning' : '']"
                  />
                  <label
                    class="form-check-label inputerror"
                    v-if="error.Vorname == true"
                  >
                    {{ error.VornameText }}
                  </label>
                </div>
                <div class="col-md-5">
                  <label for="nname" class="form-label">Nachname*</label>
                  <input
                    type="text"
                    class="form-control"
                    id="nname"
                    v-model="bewerbung.Nachname"
                    v-bind:class="[error.Nachname == true ? 'bg-warning' : '']"
                  />
                  <label
                    class="form-check-label inputerror"
                    v-if="error.Nachname == true"
                  >
                    {{ error.NachnameText }}
                  </label>
                </div>
              </div>
              <div class="row mb-3  justify-content-center">
                <div class="col-md-5 ">
                  <label for="email" class="form-label">E-mail*</label>
                  <input
                    type="email"
                    class="form-control"
                    id="email"
                    aria-describedby="emailHelp"
                    v-model="bewerbung.Email1"
                    v-bind:class="[error.Email1 == true ? 'bg-warning' : '']"
                  />
                  <label
                    class="form-check-label inputerror"
                    v-if="error.Email1 == true"
                  >
                    {{ error.Email1Text }}
                  </label>
                  
                </div>
                <div class="col-md-5">
                  <label for="email2" class="form-label"
                    >E-mail Wiederholung*</label
                  >
                  <input
                    type="email"
                    class="form-control"
                    id="email2"
                    v-model="bewerbung.Email2"
                    v-bind:class="[error.Email2 == true ? 'bg-warning' : '']"
                  />
                  <label
                    class="form-check-label inputerror"
                    v-if="error.Email2 == true"
                  >
                    {{ error.Email2Text }}
                  </label>
                </div>
              </div>
              <div class="row mb-3  justify-content-center">
                <div class="col-md-5">
                  <label for="strasse" class="form-label">Straße</label>
                  <input
                    type="text"
                    class="form-control"
                    id="strasse"
                    v-model="bewerbung.Strasse"
                  />
                </div>
                <div class="col-md-5">
                  <label for="hausnummer" class="form-label">Hausnummer</label>
                  <input
                    type="text"
                    class="form-control"
                    id="hausnummer"
                    v-model="bewerbung.Hausnummer"
                  />
                </div>
              </div>

              <div class="row mb-3 justify-content-center">
                <div class="col-md-5 ">
                  <label for="plz" class="form-label">Postleitzahl</label>
                  <input
                    type="text"
                    class="form-control"
                    id="plz"
                    v-model="bewerbung.PLZ"
                  />
                </div>
                <div class="col-md-5">
                  <label for="stadt" class="form-label">Stadt</label>
                  <input
                    type="text"
                    class="form-control"
                    id="stadt"
                    v-model="bewerbung.Stadt"
                  />
                </div>
              </div>

              <div class="row mb-3 justify-content-center">
                <div class="col-md-5 ">
                  <label for="plz" class="form-label">Geburtdatum</label>
                  <input
                    type="date"
                    class="form-control"
                    id="geburtsdatum"
                    v-model="bewerbung.Geburtdatum"
                  />
                </div>
                <div class="col-md-5">
                  <label for="telefon" class="form-label">Telefon</label>
                  <input
                    type="text"
                    class="form-control"
                    id="telefon"
                    v-model="bewerbung.Telefon"
                  />
                </div>
              </div>

              <div class="row justify-content-center">
                <div class="col-md-10 ">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="andere"
                    v-model="bewerbung.andere"
                  />
                  <label class="form-check-label" for="andere"
                    >&nbsp; Ich bin damit einverstanden, dass meine Bewerbung
                    auch für andere Stellen berücksichtigt wird.
                  </label>
                </div>
              </div>

              <div class="row mt-4 justify-content-center">
                <div class="col-md-10 ">
                  <div class="titleKlein">
                    <h5>Anlagen</h5>
                  </div>
                </div>
              </div>

              <div class="row mb-3 justify-content-center">             
                <div class="col-md-5">
                  <label for="anschreiben" class="form-label"
                    >Anschreiben</label
                  >
                  <input
                    type="file"
                    ref="anschreibenfile"
                    name="anschreiben"
                    class="form-control"
                    id="anschreiben"                    
                    @change="uploadAnschreiben"
                  />
                  <label
                    class="form-check-label inputok"                    
                  >
                    {{ bewerbung.Anschreiben }}
                  </label>
                </div>
                <div class="col-md-5">
                  <label for="lebenslauf" class="form-label">Lebenslauf*</label>
                  <input
                    type="file"
                    ref="lebenslauffile"
                    name="lebenslauf"
                    class="form-control"
                    id="lebenslauf"                    
                    @change="uploadLebenslauf"
                     
                    v-bind:class="[
                      error.Lebenslauf == true ? 'bg-warning' : '',
                    ]"
                  />
                  <label
                    class="form-check-label inputerror"
                    v-if="error.Lebenslauf == true"
                  >
                    {{ error.LebenslaufText }}
                  </label>
                  <label
                    class="form-check-label inputok"                    
                  >
                    {{ bewerbung.Lebenslauf }}
                  </label>
                </div>
              </div>

              <div class="row mb-3 justify-content-center">             
                <div class="col-md-5">
                  <label for="bild" class="form-label">Foto (optional)</label>
                  <input
                    
                    type="file"
                    ref="bildfile"
                    name="bild"
                    class="form-control"
                    id="bild"                    
                    @change="uploadBild"
                  />
                  <label
                    class="form-check-label inputok"                    
                  >
                    {{ bewerbung.Bild }}
                  </label>
                </div>
                <div class="col-md-5">
                  <label for="sonstiges" class="form-label"
                    >Sonstiges (Zeugnisse, Zertifikate, usw.)</label
                  >
                  <input                    
                    type="file"
                    ref="sonstigesfile"
                    name="sonstiges"
                    class="form-control"
                    id="sonstiges"                    
                    @change="uploadSonstiges"
                  />
                  <label
                    class="form-check-label inputok"                    
                  >
                    {{ bewerbung.Sonstiges }}
                  </label>
                </div>
              </div>

              <div class="row mt-4 justify-content-center">
                <div class="col-md-10 ">
                  <div class="titleKlein">
                    <h5>Wechsel zu uns</h5>
                  </div>
                </div>
              </div>

              <div class="row  justify-content-center">             
                <div class="col-md-5">
                  <label for="vname" class="form-label">Gehaltswusch</label>
                  <input
                    type="text"
                    class="form-control"
                    id="vname"
                    v-model="bewerbung.Gehaltswunsch"
                  />
                </div>
                <div class="col-md-5">
                  <label for="nname" class="form-label">Kündigungsfrist</label>
                  <input
                    type="text"
                    class="form-control"
                    id="nname"
                    v-model="bewerbung.Kuendigung"
                  />
                </div>
              </div>

              <div class="row mt-4 justify-content-center">
                <div class="col-md-10 ">
                  <div class="titleKlein">
                    <h5>Nächste Schritte</h5>
                  </div>
                </div>
              </div>

              <div class="row justify-content-center">
                <div class="col-md-10 ">
                  Nach Eingang Ihrer Bewerbung werden wir uns schnellst möglich mit Ihnen in Verbindung
                  setzten.
                </div>
              </div>

              <div class="row mt-5 justify-content-center">
                <div class="col-md-10 ">
                  <div class="titleKlein">
                    <h5>Datenschutzerklärung</h5>
                  </div>
                </div>
              </div>

              <div class="row justify-content-center">
                <div class="col-md-10 ">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="andere"
                    v-model="bewerbung.Datenschutz"
                    v-bind:class="[
                      error.Datenschutz == true ? 'bg-warning' : '',
                    ]"
                  />
                  <label class="form-check-label" for="andere"
                    >&nbsp; Ich habe die
                    <a class="linkSchwarz" href="/Datenschutz">Datenschutzerklärung</a> zur Kenntnis
                    genommen.
                  </label>
                  <label
                    class="form-check-label inputerror"
                    v-if="error.Datenschutz == true"
                  >
                    {{ error.DatenschutzText }}
                  </label>
                </div>
              </div>

              <div class="row mt-4 justify-content-center">
                <div class="col-md-10 ">
                {{mail.gesendet}}
                </div>
              </div>

              <div class="row mt-4 justify-content-center">
                <div class="col-md-10 ">
                  <input
                    class="btn btn-outline-dark"
                    type="submit"
                    value="Bewerbung abschicken"
                    @click="checkForm()"
                  />
                </div>
              </div>
            
            </div>
          </div>





        </div>
      </div>
    </template>
  </Layout>
</template>

<script>

const axiosConfig= {
    crossDomain: true,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };


function validEmail(email) {
  var re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}
import Layout from "@/layouts/Layout";
import axios from "axios";

export default {
  name: "AktuellesStell",

  data() {
    return {
      aktuelleSite: "bewe",
      show: false,
      bewerbung: {
        Stelle:"",
        Anrede: "",
        Titel: "",
        Vorname: "",
        Nachname: "",
        Email1: "",
        Email2: "",
        Strasse: "",
        Hausnummer: "",
        PLZ: "",
        Stadt: "",
        Geburtsdatum: "",
        Telefon: "",
        andere: true,

        Anschreiben: "",
        Lebenslauf: "",
        Bild: "",
        Sonstiges: "",

        Anschreiben_temp: "",
        Lebenslauf_temp: "",
        Bild_temp: "",
        Sonstiges_temp: "",

        Gehaltswunsch: 0,
        Kuendigung: "",
        Datenschutz: false,
      },
      error: {
        Vorname: false,
        Nachname: false,
        Email1: false,
        Email2: false,
        Lebenslauf: false,
        Datenschutz: false,
        VornameText: "",
        NachnameText: "",
        Email1Text: "",
        Email2Text: "",
        LebenslaufText: "",
        DatenschutzText: "",
      },
      mail:{
        gesendet:"",
      },
      errors: 0,
    };
  },
  components: {
    Layout,
  },
  methods: {
    checkForm: function () {
      this.errors = 0;

      if (this.bewerbung.Vorname == "") {
        this.error.Vorname = true;
        this.error.VornameText = "Bitte tragen Sie einen Vornamen ein";
        this.errors = 1;
      } else {
        this.error.Vorname = false;
      }
      if (this.bewerbung.Nachname == "") {
        this.error.Nachname = true;
        this.error.NachnameText = "Bitte tragen Sie einen Nachnamen ein";
        this.errors = 1;
      } else {
        this.error.Nachname = false;
      }

      if (this.bewerbung.Lebenslauf == "") {
        this.error.Lebenslauf = true;
        this.error.LebenslaufText =
          "Bitte übermitteln Sie uns einen Lebenslauf";
        this.errors = 1;
      } else {
        this.error.Lebenslauf = false;
      }
      if (this.bewerbung.Email1 == "") {
        this.error.Email1 = true;
        this.error.Email1Text = "Bitte tragen Sie eine E-mail Adresse ein";
        this.errors = 1;
      } else {
        this.error.Email1 = false;
      }

      if (this.bewerbung.Email2 == "") {
        this.error.Email2 = true;
        this.error.Email2Text = "Bitte Wiederholen Sie die E-mail Adresse";
        this.errors = 1;
      } else if (this.bewerbung.Email1 !== this.bewerbung.Email2) {
        this.error.Email2 = true;
        this.error.Email2Text =
          "Die Wiederholung ist nicht gleich der E-mail Adresse";
        this.errors = 1;
      } else {
        this.error.Email2 = false;
      }

      if (this.bewerbung.Datenschutz == false) {
        this.error.Datenschutz = true;
        this.error.DatenschutzText =
          "Bitte bestätigen Sie die Kenntnisnahme der Datenschutzerklärung";
        this.errors = 1;
      } else {
        this.error.Datenschutz = false;
      }
      console.log(validEmail(this.bewerbung.Email1));
      
      if (validEmail(this.bewerbung.Email1) == false) {
        this.error.Email1 = true;
        this.error.Email1Text =
          "Bitte tragen Sie eine gültige E-mail Adresse ein";
        this.errors = 1;
      } else {
        this.error.Email1 = false;
      }

      console.log(this.errors);
      if (this.errors == 0) {
        console.log("keine Fehler");
        this.sendmail();
        return true;
      }
    },

    sendmail: function () {
    const axiosConfig = {
          headers: {
            Accept: "text/plain",
            "Content-Type": "text/plain",
          },
        };
        let payload = this.bewerbung;
        
          payload.empfaenger= "info@lueftungsbau-chemnitz.de";
          payload.Grund="Bewerbung";   
        
        let mail = this.mail;
        axios
          .post("https://kito-design.de/mailrelay/post_attachment.php", payload, axiosConfig)
          .then((response) => {
            console.log("success", response.data);
            mail.gesendet = "Wir bedanken uns für Ihre Bewerbung. Ihre Kontaktdaten wurden erfolgreich versendet";  
          })
          .catch((error) => {
            console.log(error.response);
            mail.gesendet =
              "Ihre Kontaktdaten konnten leider nicht gesendet werden. Bitte rufen Sie uns an.";
          });
    },
    uploadLebenslauf() {
      let bewerbung = this.bewerbung;
      let formData = new FormData();
      for (var i = 0; i < this.$refs.lebenslauffile.files.length; i++) {
        let file = this.$refs.lebenslauffile.files[i];
        const t = Math.round(+new Date()/1000);// unix timestamp
        console.log(t);
        formData.append("uploadfile", file);
        console.log(formData);
      }

      axios
        .post("https://pillar.de/kito/php/fileupload.php", formData,axiosConfig)
        .then(function (response) {
          console.log(response.data);
          bewerbung.Lebenslauf=response.data.file_org;
          bewerbung.Lebenslauf_temp=response.data.filePath;          
        })
        .catch(function () {
          //		$('#BitteWarten').modal('toggle');
        });
    },
    uploadSonstiges() {
      let bewerbung = this.bewerbung;
      let formData = new FormData();
      for (var i = 0; i < this.$refs.sonstigesfile.files.length; i++) {
        let file = this.$refs.sonstigesfile.files[i];
        const t = Math.round(+new Date()/1000);// unix timestamp
        console.log(t);
        formData.append("uploadfile", file);
        console.log(formData);
      }

      axios
        .post("https://pillar.de/kito/php/fileupload.php", formData,axiosConfig)
        .then(function (response) {
          console.log(response.data);
          bewerbung.Sonstiges=response.data.file_org;
          bewerbung.Sonstiges_temp=response.data.filePath;          
        })
        .catch(function () {
          //		$('#BitteWarten').modal('toggle');
        });
    },
    uploadAnschreiben() {
      let bewerbung = this.bewerbung;
      let formData = new FormData();
      for (var i = 0; i < this.$refs.anschreibenfile.files.length; i++) {
        let file = this.$refs.anschreibenfile.files[i];
        const t = Math.round(+new Date()/1000);// unix timestamp
        console.log(t);
        formData.append("uploadfile", file);
        console.log(formData);
      }

      axios
        .post("https://pillar.de/kito/php/fileupload.php", formData,axiosConfig)
        .then(function (response) {
          console.log(response.data);
          bewerbung.Anschreiben=response.data.file_org;
          bewerbung.Anschreiben_temp=response.data.filePath;          
        })
        .catch(function () {
          //		$('#BitteWarten').modal('toggle');
        });
    },
    uploadBild() {
      let bewerbung = this.bewerbung;
      let formData = new FormData();
      for (var i = 0; i < this.$refs.bildfile.files.length; i++) {
        let file = this.$refs.bildfile.files[i];
        const t = Math.round(+new Date()/1000);// unix timestamp
        console.log(t);
        formData.append("uploadfile", file);
        console.log(formData);
      }

      axios
        .post("https://pillar.de/kito/php/fileupload.php", formData,axiosConfig)
        .then(function (response) {
          console.log(response.data);
          bewerbung.Bild=response.data.file_org;
          bewerbung.Bild_temp=response.data.filePath;          
        })
        .catch(function () {
          //		$('#BitteWarten').modal('toggle');
        });
    },
  },
  computed: {},
  created(){
    this.show=true;
    }
};
</script>

<style scoped></style>
