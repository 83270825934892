<template>
  <Layout>
    <template #default>
      <div>
       <div class="container-fluid titelBackground">
         <div class="row justify-content-center pt-4">
            <div class="col-md-8">
              <div>
                <Transition appear name="bounce">
                <div class="normalerText">
                  <h3 class="zitate text-center">
                    „NUR VOM NUTZEN WIRD DIE WELT REGIERT“
                  </h3>
                  <p class="zitatverweis text-center">
                    Friedrich von Schiller- deutscher Dichter, Philosoph und
                    Historiker
                  </p>
                </div>              
                </Transition>
              </div>
            </div>
          </div>

          <div class="row pt-2 pb-2 mt-5 mb-0 bg-main">
            <div class="col-md-12">
              <div class="col-12 h1Weiss fw-bold text-center">
                <h1 style="text-align: center">Impressum</h1>
              </div>
            </div>
          </div>
          
           <div class="row pb-5 pt-5 mb-0 grauHellfeld justify-content-center">
            <div class="col-sm-10 col-md-8 ">
              <div class="title">
                <p pt-5>
                  Metall & Lüftungsbau Chemnitz <br />Am Läusebach 1,<br />07318 Saalfeld
                  - Beulwitz<br /><br />Telefon:
                  <a href="tel:03671%20641311">03671 527550</a>
                </p>
                <p>
                  E-Mail:
                  <a href="mailto:info@lueftungsbau-chemnitz.de"
                    >info@lueftungsbau-chemnitz.de</a
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Layout>
</template>

<script>
import Layout from "@/layouts/Layout";
export default {
  name: "Impressum",
  components: {
    Layout,
  },
};
</script>

<style></style>
