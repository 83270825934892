<template>
  <Layout>
    <template #default>
      <div>
        <div class="container-fluid ">
          <div class="row pt-4 justify-content-center">
            <div class="col-md-8">
              <div>
                <Transition appear name="bounce">
                  <div class="normalerText">
                    <h3 class="zitate  text-center">
                      „Wer immer tut, was er schon kann, bleibt immer das, was er schon ist.“
                    </h3>
                    <p class="zitatverweis text-center">
                      Henry Ford
                    </p>
                  </div>
                </Transition>
              </div>
            </div>
          </div>
         <div class="row pt-2 pb-2 mt-5 mb-0 bg-main">
            <div class="col-md-12">
              <div class="col-12 h1Weiss fw-bold text-center">
                <h1 style="text-align: center">SERVICE</h1>
              </div>
            </div>
          </div>
         <div class="row pb-5 pt-5 mb-0 bg-center justify-content-center">
            <div class="col-sm-10 col-md-8 ">
              <div>
                <div class="">
                  <h3>Analyse und Beratung</h3>
                  <p>
                    • Am Anfang steht die Frage. Sie haben eine Aufgabestellung,
                    ein Problem, eine Zielsetzung, eine Funktionsstörung, eine
                    Schadenssituation oder Defekt. In der modernen Industrie
                    kommen immer komplexere Prozesse, Anlagen und Maschinen zur
                    Anwendung. Oft ist dies gepaart mit besonderen Anforderungen
                    an Luftreinheit, Temperatur, Dichtheit, Parameterabweichung
                    oder speziellen anderen Rahmenbedingungen.
                  </p>
                  <p>
                    Eine Vielzahl unterschiedlicher Maßnahmen in
                    anlagentechnischer oder konstruktiver Hinsicht kann
                    erforderlich sein, um am Ende das gewünschte Ziel zu
                    erreichen und die geforderte Aufgabenstellung zu erfüllen.
                  </p>
                  <p>
                    Wir beraten Sie mit unserer Erfahrung im Bereich der klima-
                    und lufttechnischen Anlagen und Einrichtungen. Wir führen
                    Messungen durch und lassen die Ergebnisse in den
                    Entscheidungsprozess einfließen. In einem kreativen
                    Problemlösungsprozess ermitteln wir gemeinsam mit Ihnen
                    systematisch einen optimalen Lösungsweg. Von der
                    Problemdefinition über die Ideenfindung bis Zielformulierung
                    und der Umsetzung begleiten wir Sie.
                  </p>

                  <h3>Planung</h3>
                  <p>
                    Umbauter Raum ist die Grundlage und Voraussetzung fast aller
                    industriellen Prozesse. Ob bei der Beschaffung, der
                    Bereitstellung oder der Unterhaltung. Oft ist die
                    Implementierung einer lufttechnischen Anlage von
                    signifikanter Bedeutung bei der Planung eines Projektes.
                    Eine Reihe unterschiedlichster Aspekte, Kriterien und
                    Sichtweisen gilt es zu berücksichtigen. Ob es der Raumbedarf
                    der technischen Ausrüstung ist, die Investitionskosten für
                    Planung und Montage, die Einschätzung des optimalen
                    technologischen Ausstattungsgrades der Anlage oder der
                    Energieverbrauch, auch Betriebs- und Instandhaltungskosten
                    oder Wartungsaufwand – wir beraten Sie und finden gemeinsam
                    mit Ihnen die beste Lösung für Ihren Anwendungsfall.
                  </p>
                  <ul>
                    <li>Räumliche Planung</li>

                    <li>Technische Planung</li>
                    <li>Projektierung, Dimensionierung</li>
                    <li>Planung des Investitionsbedarfes</li>
                    <li>Planung der terminlichen Abläufe</li>
                    <li>Realisierung</li>
                    <li>Dokumentation</li>
                  </ul>

                  <h3>Dichtheitsprobe nach DIN EN 13779</h3>
                  <p>
                    RLT- und Klimaanlagen haben oft ein weit verzweigtes System
                    an luftführenden Kanälen und Rohrleitungen in denen
                    zusätzlich eine ganze Reihe von unterschiedlichen Bauteilen
                    installiert ist. Durch Fehler bei der Herstellung und bei
                    der Montage kann es zu Undichtheiten kommen, die sich auf
                    fatale Weise bemerkbar machen. Ist die Summe der
                    Leckluftstellen (Leckluftrate) zu hoch wird beim
                    pneumatischen Abgleich der Anlage festgestellt das geplante
                    Luftmengenparameter in einzelnen Bereichen nicht erreicht
                    werden können. Die Folge unzureichende Funktion oder
                    hygienische Problme (VDI6022) durch Eintrag von Bakterien
                    und Stäuben. Ein erhöhter Energieaufwand auf Grund zu hoher
                    Volumenströme der Ventilatoren verursacht zusätzliche
                    Betriebskosten und belastet die Umwelt.
                  </p>
                  <p>
                    Nach DIN EN 18379 - 3.2.7.1 müssen alle Verbindungen der
                    Luftleitungen entsprechend den Betriebsbedingungen luftdicht
                    und stabil sein. Nach EURUVENT 2/2 und DIN EN 13779 werden
                    die zulässigen Leckluftraten definiert.
                  </p>
                  <p>
                    Wir führen für Sie die Druckprüfung durch, dokumentieren
                    diese und werten die Ergebnisse gemeinsam mit Ihnen aus, um
                    eventuell erforderliche Maßnahmen abzuleiten.
                  </p>
                  <p></p>

                  <h3>
                    Wartung und Reparatur von technischen Anlagen und
                    Einrichtungen
                  </h3>
                  <p>
                    Lüftungs- und Klimaanlagen sind technische Einrichtungen
                    deren dauerhafte Betriebssicherheit durch eine regelmäßige
                    Wartung gewährleistet werden muss. Hierbei gilt es eine
                    Reihe von Dingen zu beachten, die nicht nur für die Funktion
                    der Anlage von Bedeutung sind, sondern auch für die
                    Sicherheit und die hygienische Unbedenklichkeit.
                  </p>
                  <p>
                    In Lüftungs- und Klimaanlagen befinden sich Teile wie
                    Ventilatoren, Verdichter, Antriebe, Pumpen, Wärmetauscher
                    die einem normalen Verschleiß unterliegen. Hinzu kommt oft,
                    dass sich diese Anlagen durch ein komplexes Zusammenspiel
                    der einzelnen Elemente, Bauteile und Baugruppen auszeichnen.
                    Dieses Zusammenspiel wird durch die entsprechende
                    MSR-Technik gesteuert und geregelt. Im Weiteren gibt es
                    Sicherheitseinrichtungen wie Brand- und Rauchschutzklappen
                    sowie Rauchmelder deren regelmäßige Wartung zwingend
                    vorgeschrieben ist und nachgewiesen werden muss, da diese am
                    anderen Fall ihre Zulassung verlieren. Hygienische
                    Untersuchungen nach VDI6022 bieten die Grundlage dafür, dass
                    von diesen Anlagen keine gesundheitlichen Gefährdungen
                    ausgehen.
                  </p>
                  <p>
                    Unsere geschulten Wartungsmonteure betreuen Ihre Anlagen
                    zuverlässig und mit großer Sorgfalt. Die Wartungsarbeiten
                    werden nach VDMA 24186 Teil 1 bis 6 durchgeführt und
                    dokumentiert. Herstellerspezifische Wartungsanforderungen
                    werden dabei ebenso berücksichtigt wie Anforderungen aus
                    Zulassungen oder Prüfzertifikaten.
                  </p>
                  <p>
                    Wird bei der Durchführung der Wartungsarbeiten ein Mangel,
                    ein Defekt oder ein Schaden festgestellt, informieren wir
                    Sie umgehend, so dass über die weitere Vorgehensweise
                    entschieden werden kann. Bei Bedarf erhalten Sie von uns ein
                    kurzfristiges Reparaturangebot zu fairen Preisen. Wir
                    beraten Sie gern über Maßnahmen zur Gewährleistung einer
                    hohen Betriebssicherheit von sensibler Anlagentechnik. Dies
                    beginnt bei der Vorhaltung von Ersatzteilen, der Beurteilung
                    deren durchschnittlicher Lieferzeit über die Einrichtung
                    eines Redundanzbetriebes bis zur planmäßig vorbeugenden
                    Instandhaltung. Durch intelligent geplante Wartungen lässt
                    sich das Ausfallrisiko deutlich reduzieren und erhebliche
                    Kosten einsparen.
                  </p>

                  <h3>Lufttechnik Hygieneinspektion nach VDI6022</h3>
                  <p>
                    Die VDI6022 „Hygiene-Anforderungen an Raumlufttechnische
                    Anlagen und Geräte“ enthält nicht nur konkrete Anforderungen
                    die bei der Planung und Installation einer RLT-Anlage zu
                    berücksichtigen sind. Sie stellt auch das Regelwerk dar, auf
                    dessen Basis der Betreiber einer RLT-Anlage
                    eigenverantwortlich verpflichtet ist die Erfüllung dieser
                    Anforderungen dauerhaft sicherzustellen.
                  </p>
                  <p>
                    Bereits im November 1985 wurde in der „Kröling-Studie“
                    festgestellt welchen Einfluss der hygienische Zustand einer
                    Klimaanlage auf das Befinden und die Gesundheit des Menschen
                    haben kann. Mit dem so genannten „sick building syndrom“
                    werden Krankeitssymptome wie häufige Infekte, Juckreiz,
                    Kopfschmerzen Übelkeit u.ä. beschrieben. Diese können aus
                    Baumaterialien, Möbeln, Bodenbelägen und anderen
                    Einrichtungsgegenständen herrühren. Die Ursache kann aber
                    auch einer mangelhaften RLT- oder Klimaanlage darstellen.
                    Damals kam die gesamte Branche als „Krankmacher“ in Verruf.
                    Auch im Ergebnis dessen wurde die VDI6022 eingeführt. Sie
                    schafft die Voraussetzung zum Erreichen der in der
                    Arbeitsstättenverordnung §3 ; §3a und §4 (ArbStättV), dem
                    Arbeitsschutzgesetz (ArbSchG) §3 und §4 und der
                    Arbeitsstättenrichtlinie (ASR 5) formulierten Ziele.
                  </p>
                  <p>
                    Wir führen die Hygieneinspektionen nach VDI6022 durch. Von
                    der Erstinspektion mit der dazugehörigen Bewertung über die
                    mikrobiologische Untersuchung bis zur Auswertung bieten wir
                    Ihnen die komplette Leistung. Im Rahmen einer Wartung wird
                    von unseren geschulten und zertifizierten Mittarbeitern, die
                    wiederkehrenden Hygieneinspektion durchgeführt. Sie erhalten
                    von uns die anforderungsgerechte Dokumentation zum Nachweis.
                  </p>

                  <p></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Layout>
</template>

<script>
import Layout from "@/layouts/Layout";

export default {
  name: "Service",

  data() {
    return {
      newSite: null,
    };
  },
  components: {
    Layout,
  },
  methods: {},
  computed: {},
};
</script>

<style scoped></style>
