<template class="bg-vue">
  <div class="row bg-footer justify-content-start border-top border-2 me-0">
    <div class="col-sm-1 col-md-2 img-ausbildung" style="position: relative">
    
    </div>
    <div class="col-sm-12 col-md-12">
      <div class="row">
        <div class="col-sm-10 offset-sm-1 offset-md-0 col-md-4 text-center ps-3">
         <img
                src="../assets/chemnitz-tlogo_weiss.png"
                class="img-fluid pt-3 ps-3 slInLeft"
                style="height:80%"
                alt="..."
              />
        </div>
        <div class="col-sm-10 offset-sm-1 col-md-2  offset-md-0 text-center pt-3">
          <p class="text-start ps-2">
            <a
              title="Adresse"
              href="https://www.google.com/maps/place/Am+L%C3%A4usebach+1,+07318+Saalfeld%2FSaale/@50.6568065,11.3169647,19z/data=!3m1!4b1!4m5!3m4!1s0x47a3fce46a0c1605:0x89e32f19934d645d!8m2!3d50.6568065!4d11.3175132"
              target="_blank"
            >
              <i class="fas fa-map-marker-alt"></i
            ></a>
            <a
              href="https://www.google.com/maps/place/Am+L%C3%A4usebach+1,+07318+Saalfeld%2FSaale/@50.6568065,11.3169647,19z/data=!3m1!4b1!4m5!3m4!1s0x47a3fce46a0c1605:0x89e32f19934d645d!8m2!3d50.6568065!4d11.3175132"
            >
              Am Läusebach 1,<br />07318 Saalfeld - Beulwitz
            </a>
          </p>
        </div>
        <div class="col-md-2 offset-md-0 col-sm-10 offset-sm-1  text-center pt-3">
          <p class="text-start  ps-2">
            <a
              title="Telefon"
              href="tel:03671 527550"
              target="_blank"
              rel="noopener"
            >
              <i class="fas fa-phone"></i>
            </a>
            <a href="tel:03671 527550">03671 527550</a>
          </p>
        <p class="text-start  ps-2">
            <a
              title="Mail an info@lueftungsbau-chemnitz.de schreiben"
              href="mailto:info@lueftungsbau-chemnitz.de"
              target="_blank"
              rel="noopener"
              ><i class="fas fa-envelope"></i></a
            ><a href="mailto:info@lueftungsbau-chemnitz.de"
              >info@lueftungsbau-chemnitz.de</a
            >
          </p>
          <p class="text-start  ps-2">
            <i class='far fa-copyright'></i>    2022 by Metall & Lüftungsbau Chemnitz
          </p>
        </div>
        <div class="col-sm-10 offset-sm-1 offset-md-0  col-md-1 text-center pt-3">
          <p class="text-start ps-2">
            <a
              title="Telefon"
              href=""
              target="_blank"
              rel="noopener"
              style="font-size: 2rem;"
            >
              <i class="fab fa-facebook-f ps-5"></i>
            </a>
            
          
            <a
              title="Telefon"
              href=""
              target="_blank"
              rel="noopener"
              style="font-size: 2rem;"
            >
              <i class="fab fa-instagram ps-5"></i>
            </a>
            
            <a
              title="Telefon"
              href=""
              target="_blank"
              rel="noopener"
              style="font-size: 2rem;"
            >
            <i class='fab fa-linkedin ps-5'></i>
            </a>
            
          </p>
        </div>
        <div class="col-sm-10 offset-sm-1 col-md-2 text-center pt-3">
          <p class="text-start ps-2">
            <router-link to="/sitemap">Sitemap</router-link>
          </p>
          <p class="text-start ps-2">
            <router-link
              to="/Impressum"              
              @click="activesite = 'impressum'"
              >Impressum</router-link
            >
          </p>
          <p class="text-start ps-2">
            <router-link
              to="/Datenschutz"
              
              @click="activesite = 'datenschutz'"
              >Datenschutz</router-link
            >
          </p>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "TheNavbar",
  computed: {
    ...mapGetters({ activeScreen: "getActiveSite" }),
  },
  methods: {},
  data() {
    return {
      activesite: "",
    };
  },
  created() {},
};
</script>

<style scoped></style>
