<template>
  <Layout>
    <template #default>
      <div>
        <div class="container-fluid ">
          <div class="row pb-4 pt-4 justify-content-center">
            <div class="col-md-8 ">
              <div class="title">
                <Transition appear name="bounce">
                <h1 style="text-align: center">Seitenübersicht</h1>
                </Transition>
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-md-8  normalerText">
                <ul>
            <li><a href="https://lueftungsbau-chemnitz.de/">Home</a></li>
            <li><a href="https://lueftungsbau-chemnitz.de/Produkte">Produkte</a></li>
            <li><a href="https://lueftungsbau-chemnitz.de/Leistungen">Leistungen</a></li>
            <li><a href="https://lueftungsbau-chemnitz.de/Service">Service</a></li>
            <li><a href="https://lueftungsbau-chemnitz.de/AktuellesRef">Aktuelles</a></li>
            <ul>
                <li><a href="https://lueftungsbau-chemnitz.de/AktuellesRef">Referenzen</a></li>
                <li><a href="https://lueftungsbau-chemnitz.de/AktuellesStell">Stellenanzeigen</a></li>
                <li><a href="https://lueftungsbau-chemnitz.de/AktuellesOnl">Onlinebewerbung</a></li>

            </ul>
            <li><a href="https://lueftungsbau-chemnitz.de/Kontakt">Kontakt</a></li>
            <li><a href="https://lueftungsbau-chemnitz.de/Datenschutz">Datenschutz</a></li>
            <li><a href="https://lueftungsbau-chemnitz.de/Impressum">Impressum</a></li>

                </ul>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Layout>
</template>

<script>
import Layout from "@/layouts/Layout";

export default {
  name: "Aktuelles",

  data() {
    return {
      aktuelleSite: "ref",
    };
  },
  components: {
    Layout,
  },
  methods: {},
  computed: {},
};
</script>

<style scoped></style>
