<template>
  <Layout>
    <template #default>
      <div>
        <div class="container-fluid titelBackground">
          <div class="row pb-4 pt-4">
            <div class="col-md-8 offset-md-2">
              <div class="title">
                <Transition appear name="bounce">
                  <h1 v-if="show" style="text-align: center">AKTUELLES</h1>                  
                </Transition>
              </div>
            </div>
          </div>
          <div class="row pb-2 pt-2 mb-0 bg-main">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-2 offset-md-3  h1Weiss text-center">
                  <h5 style="text-align: center">
                   <router-link
                      to="/AktuellesRef"
                      class="nav-link"
                      v-bind:class="[
                        activeScreen == '/AktuellesRef' ? 'nav-active' : 'nav-deactiv',
                      ]"
                      >Referenzen</router-link>
                </h5>
                </div>
                <div class="col-md-2 h1Weiss text-center">
                  <h5 style="text-align: center">
                   <router-link
                      to="/AktuellesStell"
                      class="nav-link"
                      v-bind:class="[
                        activeScreen == '/AktuellesStell' ? 'nav-active' : 'nav-deactiv',
                      ]"
                      >Stellenanzeigen</router-link>
                </h5>
                </div>
                <div class="col-md-2 h1Weiss text-center">
                  <h5 style="text-align: center">
                   <router-link
                      to="/AktuellesOnl"
                      class="nav-link"
                      v-bind:class="[
                        activeScreen == '/AktuellesOnl' ? 'nav-active' : 'nav-deactiv',
                      ]"
                      >Onlinebewerbung</router-link>
                </h5>
                </div>
              </div>
            </div>
          </div>
          <div class="row pt-2 mb-0  justify-content-center">
            
            <div class="col-md-8" v-if="aktuelleSite == 'ref'">
              <div class="title">
                <h3 style="text-align: center">Referenzen</h3>
              </div>
            </div>
            <div class="col-md-8" v-if="aktuelleSite == 'stell'">
              <div class="title">
                <h3 style="text-align: center">Stellenanzeigen</h3>
              </div>
            </div>
            <div class="col-md-8 normalerText" >
              <div class="row">
                <div class="col-12">
                  <div class="title">
                    <h3 style="text-align: center">Online Bewerbung</h3>
                    <h5 style="text-align: center">
                      Wir freuen uns auf Ihre Bewerbung
                    </h5>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-8 offset-md-2">
                  <div class="titleKlein">
                    <h5>Persönliches</h5>
                  </div>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-md-8 offset-md-2">
                  <label for="job" class="form-label">Stelle</label>
                  <select
                    class="form-select"
                    aria-label="Default select example"
                    id="job"
                  >
                    <option selected>Bitte auswählen</option>
                    <option value="1">Metallbauer Lüftungsbau</option>
                    <option value="2">Servicemechaniker</option>
                    <option value="3">Auszubildende*r Büro</option>
                    <option value="3">Auszubildende*r Lüftungsbau</option>
                  </select>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-md-4 offset-md-2">
                  <label for="anrede" class="form-label">Anrede</label>
                  <select
                    class="form-select"
                    aria-label="Default select example"
                    v-model="bewerbung.Anrede"
                  >
                    <option value="0" selected>Bitte auswählen</option>
                    <option value="1">Frau</option>
                    <option value="2">Herr</option>
                    <option value="3">Neutrale Anrede</option>
                  </select>
                </div>
                <div class="col-md-4">
                  <label for="titel" class="form-label"
                    >Titel(Dr., Prof.)</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="titel"
                    v-model="bewerbung.Titel"
                  />
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-md-4 offset-md-2">
                  <label for="vname" class="form-label">Vorname*</label>
                  <input
                    type="text"
                    class="form-control"
                    id="vname"
                    v-model="bewerbung.Vorname"
                    v-bind:class="[error.Vorname == true ? 'bg-warning' : '']"
                  />
                  <label
                    class="form-check-label inputerror"
                    v-if="error.Vorname == true"
                  >
                    {{ error.VornameText }}
                  </label>
                </div>
                <div class="col-md-4">
                  <label for="nname" class="form-label">Nachname*</label>
                  <input
                    type="text"
                    class="form-control"
                    id="nname"
                    v-model="bewerbung.Nachname"
                    v-bind:class="[error.Nachname == true ? 'bg-warning' : '']"
                  />
                  <label
                    class="form-check-label inputerror"
                    v-if="error.Nachname == true"
                  >
                    {{ error.NachnameText }}
                  </label>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-md-4 offset-md-2">
                  <label for="email" class="form-label">E-mail*</label>
                  <input
                    type="email"
                    class="form-control"
                    id="email"
                    aria-describedby="emailHelp"
                    v-model="bewerbung.Email1"
                    v-bind:class="[error.Email1 == true ? 'bg-warning' : '']"
                  />
                  <label
                    class="form-check-label inputerror"
                    v-if="error.Email1 == true"
                  >
                    {{ error.Email1Text }}
                  </label>
                  <div id="emailHelp" class="form-text">
                    Wir werden die Mailadresse niemals weitergeben
                  </div>
                </div>
                <div class="col-md-4">
                  <label for="email2" class="form-label"
                    >E-mail Wiederholung*</label
                  >
                  <input
                    type="email"
                    class="form-control"
                    id="email2"
                    v-model="bewerbung.Email2"
                    v-bind:class="[error.Email2 == true ? 'bg-warning' : '']"
                  />
                  <label
                    class="form-check-label inputerror"
                    v-if="error.Email2 == true"
                  >
                    {{ error.Email2Text }}
                  </label>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-md-4 offset-md-2">
                  <label for="strasse" class="form-label">Straße</label>
                  <input
                    type="text"
                    class="form-control"
                    id="strasse"
                    v-model="bewerbung.Strasse"
                  />
                </div>
                <div class="col-md-4">
                  <label for="hausnummer" class="form-label">Hausnummer</label>
                  <input
                    type="text"
                    class="form-control"
                    id="hausnummer"
                    v-model="bewerbung.Hausnummer"
                  />
                </div>
              </div>

              <div class="row mb-3">
                <div class="col-md-4 offset-md-2">
                  <label for="plz" class="form-label">Postleitzahl</label>
                  <input
                    type="text"
                    class="form-control"
                    id="plz"
                    v-model="bewerbung.PLZ"
                  />
                </div>
                <div class="col-md-4">
                  <label for="stadt" class="form-label">Stadt</label>
                  <input
                    type="text"
                    class="form-control"
                    id="stadt"
                    v-model="bewerbung.Stadt"
                  />
                </div>
              </div>

              <div class="row mb-3">
                <div class="col-md-4 offset-md-2">
                  <label for="plz" class="form-label">Geburtdatum</label>
                  <input
                    type="date"
                    class="form-control"
                    id="geburtsdatum"
                    v-model="bewerbung.Geburtdatum"
                  />
                </div>
                <div class="col-md-4">
                  <label for="telefon" class="form-label">Telefon</label>
                  <input
                    type="text"
                    class="form-control"
                    id="telefon"
                    v-model="bewerbung.Telefon"
                  />
                </div>
              </div>

              <div class="row">
                <div class="col-md-8 offset-md-2">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="andere"
                    v-model="bewerbung.andere"
                  />
                  <label class="form-check-label" for="andere"
                    >&nbsp; Ich bin damit einverstanden, dass meine Bewerbung
                    auch für andere Stellen berücksichtigt wird.
                  </label>
                </div>
              </div>

              <div class="row mt-4">
                <div class="col-md-8 offset-md-2">
                  <div class="titleKlein">
                    <h5>Anlagen</h5>
                  </div>
                </div>
              </div>

              <div class="row mb-3">
                <div class="col-md-4 offset-md-2">
                  <label for="anschreiben" class="form-label"
                    >Anschreiben</label
                  >
                  <input
                    type="file"
                    class="form-control"
                    id="anschreiben"
                    v-bind="bewerbung.Anschreiben"
                  />
                </div>
                <div class="col-md-4">
                  <label for="lebenslauf" class="form-label">Lebenslauf*</label>
                  <input
                    type="file"
                    class="form-control"
                    id="lebenslauf"
                    v-bind="bewerbung.Lebenslauf"
                    v-bind:class="[
                      error.Lebenslauf == true ? 'bg-warning' : '',
                    ]"
                  />
                  <label
                    class="form-check-label inputerror"
                    v-if="error.Lebenslauf == true"
                  >
                    {{ error.LebenslaufText }}
                  </label>
                </div>
              </div>

              <div class="row">
                <div class="col-md-4 offset-md-2">
                  <label for="bild" class="form-label">Foto(optional)</label>
                  <input
                    type="file"
                    class="form-control"
                    id="bild"
                    v-bind="bewerbung.Bild"
                  />
                </div>
                <div class="col-md-4">
                  <label for="sonstiges" class="form-label"
                    >Sonstiges(Zeugnisse, Zertifikate, usw.)</label
                  >
                  <input
                    type="file"
                    class="form-control"
                    id="sonstiges"
                    v-bind="bewerbung.Sonstiges"
                  />
                </div>
              </div>

              <div class="row mt-4">
                <div class="col-md-8 offset-md-2">
                  <div class="titleKlein">
                    <h5>Wechsel zu uns</h5>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-4 offset-md-2">
                  <label for="vname" class="form-label">Gehaltswusch</label>
                  <input
                    type="text"
                    class="form-control"
                    id="vname"
                    v-model="bewerbung.Gehalswunsch"
                  />
                </div>
                <div class="col-md-4">
                  <label for="nname" class="form-label">Kündigungsfrist</label>
                  <input
                    type="text"
                    class="form-control"
                    id="nname"
                    v-model="bewerbung.Kuendigung"
                  />
                </div>
              </div>

              <div class="row mt-4">
                <div class="col-md-8 offset-md-2">
                  <div class="titleKlein">
                    <h5>Nächste Schritte</h5>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-8 offset-md-2">
                  Nach Eingang Ihrer Bewerbung erhalten Sie von uns eine
                  Eingangsbestätigung. <br />
                  Darin ist ein Link um die Bewerbung und die Emailadresse zu
                  bestätigen. <br />
                  Nachdem sie ihre Bewerbung und E-mailadresse bestätigt haben,
                  werden wir uns schnellst möglich mit Ihnen in Verbindung
                  setzten.
                </div>
              </div>

              <div class="row mt-4">
                <div class="col-md-8 offset-md-2">
                  <div class="titleKlein">
                    <h5>Datenschutzerklärung</h5>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-8 offset-md-2">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="andere"
                    v-model="bewerbung.Datenschutz"
                    v-bind:class="[
                      error.Datenschutz == true ? 'bg-warning' : '',
                    ]"
                  />
                  <label class="form-check-label" for="andere"
                    >&nbsp; Ich habe die
                    <a href="/Datenschutz">Datenschutzerklärung</a> zur Kenntnis
                    genommen.
                  </label>
                  <label
                    class="form-check-label inputerror"
                    v-if="error.Datenschutz == true"
                  >
                    {{ error.DatenschutzText }}
                  </label>
                </div>
              </div>

              <div class="row mt-5">
                <div class="col-md-8 offset-md-2">
                  <input
                    class="btn btn-success"
                    type="submit"
                    value="Bewerbung abschicken"
                    @click="checkForm()"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Layout>
</template>

<script>
function validEmail(email) {
  var re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

import Layout from "@/layouts/Layout";

export default {
  name: "AktuellesOnl",

  data() {
    return {
      aktuelleSite: "bewe",
      show: false,
      bewerbung: {
        Anrede: 0,
        Titel: "",
        Vorname: "",
        Nachname: "",
        Email1: "",
        Email2: "",
        Strasse: "",
        Hausnummer: "",
        PLZ: "",
        Stadt: "",
        Geburtsdatum: "",
        Telefon: "",
        andere: true,
        Anschreiben: "",
        Lebenslauf: "",
        Bild: "",
        Sonstiges: "",
        Gehalswunsch: 0,
        Kuendigung: "",
        Datenschutz: false,
      },
      error: {
        Vorname: false,
        Nachname: false,
        Email1: false,
        Email2: false,
        Lebenslauf: false,
        Datenschutz: false,
        VornameText: "",
        NachnameText: "",
        Email1Text: "",
        Email2Text: "",
        LebenslaufText: "",
        DatenschutzText: "",
      },
      errors: 0,
    };
  },
  components: {
    Layout,
  },
  created(){
    this.show=true;
    },
  methods: {
    checkForm: function () {
      this.errors = 0;

      if (this.bewerbung.Vorname == "") {
        this.error.Vorname = true;
        this.error.VornameText = "Bitte tragen Sie einen Vornamen ein";
        this.errors = 1;
      } else {
        this.error.Vorname = false;
      }
      if (this.bewerbung.Nachname == "") {
        this.error.Nachname = true;
        this.error.NachnameText = "Bitte tragen Sie einen Nachnamen ein";
        this.errors = 1;
      } else {
        this.error.Nachname = false;
      }

      if (this.bewerbung.Lebenslauf == "") {
        this.error.Lebenslauf = true;
        this.error.LebenslaufText =
          "Bitte übermitteln Sie uns einen Lebenslauf";
        this.errors = 1;
      } else {
        this.error.Lebenslauf = false;
      }
      if (this.bewerbung.Email1 == "") {
        this.error.Email1 = true;
        this.error.Email1Text = "Bitte tragen Sie eine E-mail Adresse ein";
        this.errors = 1;
      } else {
        this.error.Email1 = false;
      }

      if (this.bewerbung.Email2 == "") {
        this.error.Email2 = true;
        this.error.Email2Text = "Bitte Wiederholen Sie die E-mail Adresse";
        this.errors = 1;
      } else if (this.bewerbung.Email1 !== this.bewerbung.Email2) {
        this.error.Email2 = true;
        this.error.Email2Text =
          "Die Wiederholung ist nicht gleich der E-mail Adresse";
        this.errors = 1;
      } else {
        this.error.Email2 = false;
      }

      if (this.bewerbung.Datenschutz == false) {
        this.error.Datenschutz = true;
        this.error.DatenschutzText =
          "Bitte bestätigen Sie die Kenntnisnahme der Datenschutzerklärung";
        this.errors = 1;
      } else {
        this.error.Datenschutz = false;
      }
      console.log(validEmail(this.bewerbung.Email1));
      if (validEmail(this.bewerbung.Email1) == false) {
        this.error.Email1 = true;
        this.error.Email1Text =
          "Bitte tragen Sie eine gültige E-mail Adresse ein";
        this.errors = 1;
      } else {
        this.error.Email1 = false;
      }

      if (this.errors == 0) {
        return true;
      }
    },
  },
  computed: {},
};
</script>

<style scoped></style>
