<template>
  <Layout>
    <template #default>
      <div>
        <div class="container-fluid titelBackground">
          <div class="row pt-4">
            <div class="col-md-8 offset-md-2">
              <div>
                <Transition appear name="bounce">
                  <div class="normalerText">
                    <h3 class="zitate  text-center">
                      „NUR VOM NUTZEN WIRD DIE WELT REGIERT“
                    </h3>
                    <p class="zitatverweis text-center">
                      Friedrich von Schiller- deutscher Dichter, Philosoph und
                      Historiker
                    </p>
                  </div>
                </Transition>
              </div>
            </div>
          </div>

          <div class="row pt-2 pb-2 mt-5 mb-0 bg-main">
            <div class="col-md-12">
              <div class="col-12 h1Weiss fw-bold text-center">
                <h1 style="text-align: center">PRODUKTE</h1>
              </div>
            </div>
          </div>
          <div class="row pb-1 pt-5 mb-0 bg-center">
            <div class="col-sm-10 offset-sm-1 col-md-8 offset-md-2">
              <div>
                
                  <p>
                    Die eigene Konstruktion und Fertigung erlaubt es uns durch
                    hohe Kenntnis der technologischen Mittel und Verfahren
                    kundenspezifische Komponenten, Bauteile oder Baugruppen
                    herzustellen. </p><p>Wir sind in der Lage mit größtmöglicher
                    Flexibilität Produkte anzubieten, die auf die sehr
                    unterschiedlichen Anforderungen unserer Kunden zugeschnitten
                    sind. </p><p>Durch unsere langjährige Erfahrung unserer gut
                    ausgebildeten Mittarbeiter im Bereich der Metall- und
                    Blechverarbeitung können wir auch komplizierte Bauteile in
                    bester Qualität herstellen. Durch eine eigene Konstruktion
                    und Fertigungsvorbereitung wird ein reproduzierbares
                    Qualitätsniveau sichergestellt. </p><p>Bei neu entwickelten,
                    komplexen Bauteilen halten wir den ständigen Kontakt zu
                    unserem Kunden. Ziel ist es dabei auftretende Probleme
                    während des Fertigungsprozesses zu erkennen und eventuelle
                    Konstruktionsmängel die in das Produkt einfließen könnten zu
                    vermeiden. </p><p>Die Einhaltung der vereinbarten Termine sind
                    dabei immer unser Anspruch.
                  </p>
                
              </div>
            </div>
          </div>
          <div class="row pb-5 pt-1 mb-0 bg-center">
            <div class="col-12">
              <div class="row mt-5 d-none bg-main p-2 d-lg-block">
                <div class="col-md-12 ">
                  <carouselWrapProdukte></carouselWrapProdukte>
                </div>
              </div>
              <div class="row pb-5 pt-5 mb-0 bg-center justify-content-center">
                <div class="col-sm-10  col-md-8">
                  <ul>
                    <li>
                      Sonderbauteile zur Erfassung oder Verteilung der Luft wie
                      Hauben, Verteilerkästen, Kappen, Absperreinrichtungen,
                      Saugdüsen, Abscheiderkästen- und Behälter
                    </li>
                    <li>
                      Blechverarbeitung auf modernen CNC – gesteuerten Umform-
                      und Schneidmaschinen
                    </li>
                    <li>
                      Herstellung von Blech- und Schweißbauteilen nach
                      Kundenwunsch, als Unikat oder in Kleinserie
                    </li>
                    <li>
                      Filterkästen vom Standartfilter über Schwebstofffilter bis
                      zum Aktivkohlefilter
                    </li>
                    <li>Industrieschalldämpfer</li>
                    <li>Musterbau</li>
                    <li>Medienschächte, Medienkanäle und Mediensäulen</li>
                    <li>
                      Wetterschutzgitter für Einbau in Wänden, in schrägen
                      Dachflächen oder für horizontale Montage
                    </li>
                    <li>Zyklonabscheider und Tropfenabscheider</li>
                    <li>Fortluft- und Zulufthauben</li>
                    <li>Fortluft- und Zulufttürme</li>
                    <li>Sprühnebelabscheider</li>
                    <li>Wärmetauschersysteme zur Wärmerückgewinnung</li>
                    <li>
                      Lüftungskanäle und Formstücke aus verzinktem Stahlblech,
                      Edelstahl oder Aluminium
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Layout>
</template>

<script>
import Layout from "@/layouts/Layout";
import carouselWrapProdukte from "../components/carouselWrapProdukte.vue";

export default {
  name: "Produkte",

  data() {
    return {
      newSite: null,
    };
  },
  components: {
    Layout,
    carouselWrapProdukte,
  },
  methods: {},
  computed: {},
};
</script>

<style scoped></style>
