<template class="bg-vue">
  <div class="row bg-navbar justify-content-center me-0 titleHeight"
  style="  padding-bottom:7px;
    padding-top: 17px;">
    <div class="col-sm-12 col-md-1 pb-2  ">

    </div>
    <div class="col-sm-12 col-md-8 pb-2  ">
      <ul class="nav nav-pills  nav-item">
        <li class="me-4 ">
          <router-link
            to="/"
            class="nav-link"
            v-bind:class="[activeScreen == '/' ? 'nav-active' : 'nav-deactiv']"
            >Start</router-link
          >
        </li>
        <li class="me-4">
          <router-link
            to="/Leistungen"
            class="nav-link"
            v-bind:class="[
              activeScreen == '/Leistungen' ? 'nav-active' : 'nav-deactiv',
            ]"
            >Leistungen</router-link
          >
        </li>
        <li class="me-4">
          <router-link
            to="/AktuellesRef"
            class="nav-link"
            v-bind:class="[
              activeScreen == '/AktuellesRef' ? 'nav-active' : 'nav-deactiv',
            ]"
            >Referenzen</router-link
          >
        </li>
        <li class="me-4">
          <router-link
            to="/AktuellesStell"
            class="nav-link"
            v-bind:class="[
              activeScreen == '/AktuellesStell' ? 'nav-active' : 'nav-deactiv',
            ]"
            >Karriere</router-link
          >
        </li>   
        <li class="me-4">
          <router-link
        to="/Kontakt"
            class="nav-link"
        v-bind:class="[
          activeScreen == '/Kontakt' ? 'nav-active' : 'nav-deactiv',
        ]"
        >Kontakt</router-link
      >
        </li>        
      </ul>
    </div>
 

    <div
      class="col-sm-6 col-md-1 fw-lighter img-ausbildung d-none d-lg-block"
      style="position: relative; transform: rotate(10deg)"
    >
      <router-link to="/AktuellesStell"
        ><img
          src="../assets/wirbildenaus.png"
          height="220"
          alt="..."
          style="display: block"
      /></router-link>
    </div>
  </div>

  <div class="row" v-if="activeScreen != '/'">
    <div class="col-sm-6 col-md-3">
      <img
        src="../assets/chemnitz-tlogoWhite.png"
        class="img-fluid pt-3 ps-3"
        alt="..."
      />
    </div>
    <div class="col-sm-6 col-md-2 offset-md-3"></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "TheNavbar",
  computed: {
    ...mapGetters({ activeScreen: "getActiveSite" }),
  },
  methods: {
    toggleDdown() {
      this.visi = true;
      setTimeout(() => (this.visi = false), 2000);
    },

  /**   ifAktuell() {
      if (this.activeScreen == "/AktuellesRef") {
        return true;
      } else if (this.activeScreen == "/AktuellesStell") {
        return true;
      } else if (this.activeScreen == "/AktuellesOnl") {
        return true;
      } else {
        return false;
      }
    },*/
  },
  data() {
    return {
      visi: false,
    };
  },
  created() {},
};
</script>

<style scoped></style>
