<template>
  <carousel 
    :items-to-show="1"
    :wrap-around="true" 
    :autoplay="3000">
    <slide v-for="slide in slides" :key="slide">      
      <img :src="getImgUrl(slide)"  v-bind:alt="pic" v-bind:height="250" class="p-2">
    </slide>
    <template #addons>
      <navigation />
      <pagination />
    </template>
  </carousel>
</template>


<script>
// If you are using PurgeCSS, make sure to whitelist the carousel CSS classes
//import "vue3-carousel/dist/carousel.css";
import "../eigen_css/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";

export default {
  name: "App",
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  methods: {
    getImgUrl(pet) {
      var images = require.context("../assets/", false, /\.jpg$/);
      console.log(images);
      return images("./" + pet + ".jpg");
    },
  },
  data() {
    const slides = [
      "prod1",
      "prod2",
      "prod3",
      "prod4",
      "prod5",
      "prod6",
      "prod7",
      "prod8",
      "prod9",
      "prod10",
          ];
    return {
      slides,
    };
  },
};
</script>
