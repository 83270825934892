<template>
  <carousel 
    :items-to-show="2.5" 
    :wrap-around="true" 
    :autoplay="3000">
    <slide v-for="slide in slides" :key="slide">
      <router-link to="/Leistungen"
        ><img
          :src="getImgUrl(slide.bild)"
          v-bind:alt="pic"
          v-bind:height="350"
          v-bind:class="imgCH" />
        <span class="spanCH">
          {{slide.text}}
          </span
      ></router-link>
    </slide>
    <template #addons>
      <navigation />
      <pagination />
    </template>
  </carousel>
</template>

<script>
// If you are using PurgeCSS, make sure to whitelist the carousel CSS classes
import "../eigen_css/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";

export default {
  name: "App",
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },

  methods: {
    getImgUrl(pet) {
      var images = require.context("../assets/", false, /\.jpg$/);
      console.log(images);
      return images("./" + pet + ".jpg");
    },
  },
  data() {
    const slides = [
      {bild:"Chemnitz_Bild2",text:"TECHNISCHE PLANUNG"},
      {bild:"Chemnitz_Bild3",text:"BLECHBEARBEITUNG"},
      {bild:"Chemnitz_Bild8",text:"SONDERBAUTEILE"},
      {bild:"Chemnitz_Bild6",text:"LÜFTUNGSBAU"},
      {bild:"Chemnitz_Bild7",text:"METALLARBEITEN"},
      
    ];
    return {
      slides,
    };
  },
};
</script>
