<template>
  <div class="card text-center text-vue" style="width: 1002px;height: 900px;">
    <div class="card-header">
      <div class="container-fluid">
        <div class="row" >
          <div class="col-12">
            <img
              src="../assets/ref/Fachhochschule_1.jpg"
              class="img-fluid"
              alt="..."
              v-if="Imagenumber == 1"
            />
            <img
              src="../assets/ref/Fachhochschule_2.jpg"
              class="img-fluid"
              alt="..."
              v-if="Imagenumber == 2"
            />
            <img
              src="../assets/ref/Stadthalle.jpg"
              class="img-fluid"
              alt="..."
              v-if="Imagenumber == 3"
            />
            <img
              src="../assets/ref/Frei_1.jpg"
              class="img-fluid"
              alt="..."
              v-if="Imagenumber == 4"
            />
            <img
              src="../assets/ref/AnnaAmalia.jpg"
              class="img-fluid"
              alt="..."
               style="max-height: 800px;"
              v-if="Imagenumber == 5"
            />
            <img
              src="../assets/ref/Aeropharm_1.jpg"
              class="img-fluid"
              alt="..."
               style="max-height: 800px;"
              v-if="Imagenumber == 6"
            />
            <img
              src="../assets/ref/Formenwerkstatt.jpg"
              class="img-fluid"
              alt="..."
              v-if="Imagenumber == 7"
            />
            <img
              src="../assets/ref/Aeropharm_2.jpg"
              class="img-fluid"
              alt="..."
              v-if="Imagenumber == 8"
            />
            <img
              src="../assets/ref/Kennametal.jpg"
              class="img-fluid"
              alt="..."
              v-if="Imagenumber == 9"
            />
            <img
              src="../assets/ref/TITK.jpg"
              class="img-fluid"
              alt="..."
               style="max-height: 800px;"
              v-if="Imagenumber == 10"
            />
            <img
              src="../assets/ref/FertigungslinieII.jpg"
              class="img-fluid"
              alt="..."
               style="max-height: 800px;"
              v-if="Imagenumber == 11"
            />
            <img
              src="../assets/ref/RWTH.jpg"
              class="img-fluid"
              alt="..."
              v-if="Imagenumber == 12"
            />
            <img
              src="../assets/ref/Helsingborg_1.jpg"
              class="img-fluid"
              alt="..."
              v-if="Imagenumber == 13"
            />
            <img
              src="../assets/ref/Vishay_1.jpg"
              class="img-fluid"
              alt="..."
              v-if="Imagenumber == 14"
            />
            <img
              src="../assets/ref/BASF_1.jpg"
              class="img-fluid"
              alt="..."
               style="max-height: 800px;"
              v-if="Imagenumber == 15"
            />
            <img
              src="../assets/ref/PET.jpg"
              class="img-fluid"
              alt="..."
              v-if="Imagenumber == 16"
            />
            <img
              src="../assets/ref/UV-Technik.jpg"
              class="img-fluid"
              alt="..."
               style="max-height: 800px;"
              v-if="Imagenumber == 17"
            />
            <img
              src="../assets/Strangschema-001.jpg"
              class="img-fluid"
              alt="..."
              v-if="Imagenumber == 18"
            />
            <img
              src="../assets/Werkplan_Lüftung-001.jpg"
              class="img-fluid"
              alt="..."
              v-if="Imagenumber == 19"
            />
            <img
              src="../assets/m1.jpg"
              class="img-fluid"
              alt="..."
              v-if="Imagenumber == 20"
            />
            <img
              src="../assets/il.jpg"
              class="img-fluid"
              alt="..."
              v-if="Imagenumber == 21"
            />
            <img
              src="../assets/ra.jpg"
              class="img-fluid"
              alt="..."
              v-if="Imagenumber == 22"
            />
            <img
              src="../assets/aussen.jpg"
              class="img-fluid"
              alt="..."
              v-if="Imagenumber == 23"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="card-body">
      <button type="button" class="btn btn-danger" @click="close()">
        Schließen
      </button>
    </div>
    <!-- Modal footer -->
    <div class="modal-footer"></div>
  </div>
</template>
<script>
import { closeModal } from "jenesius-vue-modal";
export default {
  name: "ModalsImageDisplay",
  props: { Imagenumber: Number },
  methods: {
    close() {
      closeModal();
    },
  },
};
</script>
<style scoped>
img {
            /* img width responds to wrapper width*/
            max-width: 100%;
            height: 90%;

        }
</style>
