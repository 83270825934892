<template>
  <Layout>
    <template #default>
      <div>
        <div class="container-fluid p-0 ">
          <div class="row  justify-content-center inputerror">
            <div class="col-md-8 ">
              <h1 class="rotSgrauBig fw-bolder pb-4" style="text-align: center">LEISTUNGEN</h1>              
            </div>
          </div>

          <div class="row pt-2 pb-2   bg-main" id="t0">
            <div class="col-md-12  pt-5 pb-5">
              <div class="row">
                <div class="col-12 h1Weiss pb-5 fw-bold text-center">
                  <div>
                    <Transition appear name="bounce">
                      <div class="">
                        <h3 class="zitate  text-center">
                          „VISION IST DIE KUNST UNSICHTBARE DINGE ZU SEHEN“
                        </h3>
                        <p class="zitatverweis text-center">
                          Jonathan Swift - Schriftsteller und
                          Satiriker
                        </p>
                      </div>
                    </Transition>
                  </div>
                  
                </div>
              </div>


        

              <div class="row justify-content-center">
                <div class="col-md-10 fw-bold text-center ">


              



                  <div class="row">
                    <div class="col-md-2 text-center">
                      <a href="#t1" class="h5AnkerLink">
                        <img
                          src="../assets/klein2.jpg"
                          class="img-fluid mx-auto d-block hoverBig"
                          alt="..."
                        />
                        <h5 class="textbalken">Tech. Planung</h5>
                      </a>
                    </div>
                    <div class="col-md-2 text-center">
                      <a href="#t2" class="h5AnkerLink">
                        <img
                          src="../assets/klein3.jpg"
                          class="img-fluid mx-auto d-block hoverBig"
                          alt="..."
                        />
                        <h5  class="textbalken">Blecharbeiten</h5>
                      </a>
                    </div>
                    <div class="col-md-2 text-center">
                      <a href="#t3" class="h5AnkerLink">
                        <img
                          src="../assets/klein6.jpg"
                          class="img-fluid mx-auto d-block hoverBig"
                          alt="..."
                        />
                        <h5  class="textbalken">Lüftungsbau</h5>
                      </a>
                    </div>
                    <div class="col-md-2 text-center">
                      <a href="#t2" class="h5AnkerLink">
                        <img
                          src="../assets/klein7.jpg"
                          class="img-fluid mx-auto d-block hoverBig"
                          alt="..."
                        />
                        <h5  class="textbalken">Metallbearbeitung</h5>
                      </a>
                    </div>
                    <div class="col-md-2 text-center">
                      <a href="#t4" class="h5AnkerLink">
                        <img
                          src="../assets/klein1.jpg"
                          class="img-fluid mx-auto d-block hoverBig"
                          alt="..."
                        />
                        <h5  class="textbalken">Produkte</h5>
                      </a>
                    </div>
                    <div class="col-md-2 text-center">
                      <a href="#t5" class="h5AnkerLink">
                        <img
                          src="../assets/klein5.jpg"
                          class="img-fluid mx-auto d-block hoverBig"
                          alt="..."
                        />
                        <h5  class="textbalken">Service</h5>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row pb-5 pt-5 mb-0  m-0 bg-center justify-content-center" id="t1">
            <a href="#t0" class="sticky">
              <i class='fas fa-angle-double-up' style='font-size:36px'></i>
            </a>

            <div class="col-sm-10  col-md-8 ">
              <h1 class="rotSgrau fw-bolder pb-4">Technische Planung</h1>
              <h3 class="rotSgrau fw-bolder">
                Analyse und Beratung
              </h3>
              <div class="row">

                <div class="col-md-6 ps-0">
                  <img src="../assets/plan1.jpg"
                          class="img-fluid  d-block pt-3 pb-4  imageToBig"
                      @click="showImage(18)"/>
                  </div>
                <div class="col-md-6 p-4"><p>
                    Am Anfang steht die Frage. Sie haben eine Aufgabestellung,
                    ein Problem, eine Zielsetzung, eine Funktionsstörung, eine
                    Schadenssituation oder Defekt. In der modernen Industrie
                    kommen immer komplexere Prozesse, Anlagen und Maschinen zur
                    Anwendung. Oft ist dies gepaart mit besonderen Anforderungen
                    an Luftreinheit, Temperatur, Dichtheit, Parameterabweichung
                    oder speziellen anderen Rahmenbedingungen.
                  </p>
                  <p>
                    Eine Vielzahl unterschiedlicher Maßnahmen in
                    anlagentechnischer oder konstruktiver Hinsicht kann
                    erforderlich sein, um am Ende das gewünschte Ziel zu
                    erreichen und die geforderte Aufgabenstellung zu erfüllen.
                  </p></div>
              </div>
                  
                  <p class="pb-3">
                    Wir beraten Sie mit unserer Erfahrung im Bereich der klima-
                    und lufttechnischen Anlagen und Einrichtungen. Wir führen
                    Messungen durch und lassen die Ergebnisse in den
                    Entscheidungsprozess einfließen. In einem kreativen
                    Problemlösungsprozess ermitteln wir gemeinsam mit Ihnen
                    systematisch einen optimalen Lösungsweg. Von der
                    Problemdefinition über die Ideenfindung bis Zielformulierung
                    und der Umsetzung begleiten wir Sie.
                  </p>

                  <h3 class="rotSgrau fw-bolder pt-5">Planung</h3>
                  <div class="row">
                <div class="col-md-6 ps-0">
                  <img src="../assets/plan2.jpg" 
                          class="img-fluid  d-block pt-3 pb-4  imageToBig"
                      @click="showImage(19)"/>
                </div>
                <div class="col-md-6">
                  <p>
                  </p>
                  <p>
                    Umbauter Raum ist die Grundlage und Voraussetzung fast aller
                    industriellen Prozesse. Ob bei der Beschaffung, der
                    Bereitstellung oder der Unterhaltung. Oft ist die
                    Implementierung einer lufttechnischen Anlage von
                    signifikanter Bedeutung bei der Planung eines Projektes.
                    Eine Reihe unterschiedlichster Aspekte, Kriterien und
                    Sichtweisen gilt es zu berücksichtigen. Ob es der Raumbedarf
                    der technischen Ausrüstung ist, die Investitionskosten für
                    Planung und Montage, die Einschätzung des optimalen
                    technologischen Ausstattungsgrades der Anlage oder der
                    Energieverbrauch, auch Betriebs- und Instandhaltungskosten
                    oder Wartungsaufwand – wir beraten Sie und finden gemeinsam
                    mit Ihnen die beste Lösung für Ihren Anwendungsfall.
                  </p>
                </div>
                  </div>
                  
                  <ul class="ps-3">
                    <li>Räumliche Planung</li>

                    <li>Technische Planung</li>
                    <li>Projektierung, Dimensionierung</li>
                    <li>Planung des Investitionsbedarfes</li>
                    <li>Planung der terminlichen Abläufe</li>
                    <li>Realisierung</li>
                    <li>Dokumentation</li>
                  </ul>
            </div>
          </div>


           <div class="row pb-5 pt-5 mb-0 m-0 grauDunkelfeld justify-content-center" id="t2">
             <div class="col-sm-10  col-md-8">
              <h1 class="rotSweiss fw-bolder pb-4">Blech- und Metallarbeiten</h1>
              
              <div class="row">
                <div class="col-md-8 col-sm-12 pt-4 ps-0">
                  <p>
                   Durch unsere langjährige Erfahrung unserer gut ausgebildeten Mittarbeiter im Bereich der Metall- und Blechverarbeitung 
                   können wir auch komplizierte Bauteile in bester Qualität herstellen. Durch eine eigene Konstruktion und Fertigungsvorbereitung 
                   wird ein reproduzierbares Qualitätsniveau sichergestellt.
                  </p>
                  <p>
                      Desweiteren sind wir in der Lage auf 3D basierenden Daten Bauteile zu produzieren und/ 
                      oder weiterzuverarbeiten bis hin zum fertigen Produkt. Wir bieten Ihnen Prozessoptimierte 
                      Lösungen bis hin zur Serienreife, hierbei greifen wir auf  modernste CAD-Systeme zurück.
                  </p>
                  <p>
                    Wir begleiten sie vom ersten Gedankengang bis hin zum fertigen Produkt.
                  </p>
                  <ul class="ps-3 pb-5" >
                    <li>Blechverarbeitung auf modernen CNC – gesteuerten Umform- und Schneidmaschinen ( Fiber-Laser / Abkantpresse / Schwenkbiege)</li>
                    <li>Herstellung von Blech- und Schweißbauteilen nach Kundenwunsch, als Unikat oder in Serie</li>
                  </ul>
                </div>
                <div class="col-md-4  col-sm-12">
                  
                   
                   
                </div>
              </div>
              <div class="row  justify-content-center">
                <div class="col-dm-10 ps-0">
                    <video controls class="videoClassGross" width="750">
                      <source  src="../assets/Video.mp4"
                              type="video/mp4">
                      Sorry, your browser doesn't support embedded videos.
                    </video>
                    <video controls class="videoClassKlein" width="290">

                        <source  src="../assets/Video.mp4"
                                type="video/mp4">

                        Sorry, your browser doesn't support embedded videos.
                    </video>
                </div>
              </div>
              
            </div>
          </div>


          <div class="row pb-5 pt-5 mb-0 m-0 bg-center justify-content-center" id="t3">
            <div class="col-sm-10  col-md-8 ">
              <h1 class="rotSgrau fw-bolder pb-4">Lüftungsbau</h1>
              <h3 class="rotSweiss fw-bolder pb-4">
                Industrielle Lüftungsanlagen und Anlagentechnik
              </h3>

              <p>
                Bei vielen technologischen Abläufen und Fertigungsverfahren
                entstehen Abfallprodukte in Form von Gasen, Dämpfen, Nebeln,
                Rauchen, Aerosolen, Stäuben, Spänen, allgemeinen Schadstoffen
                oder Abwärme. Häufig ist eine primäre Vermeidung
                verfahrensbedingt nicht möglich. Die vollständige lokale
                Erfassung der Gefahrstoffe direkt an der Entstehungs- oder
                Austrittsstelle ist nicht immer möglich. Diesem Fall müssen
                entsprechende Lüftungsmaßnahmen zur Anwendung kommen.
              </p>

              <p>
                Oft ist auch die Einhaltung von definierten Prozessbedingungen
                oder Raumluftzuständen in Bezug auf Temperatur,
                Luftfeuchtigkeit, Luftreinheit oder Strömungsgeschwindigkeit
                gefordert.
              </p>

              <p class="pb-4">
                Für die Einhaltung dieser Anforderungen ist nicht selten der
                Einsatz einer systemoptimierten Lüftungsanlage die einzige
                Möglichkeit einen stabilen Fertigungsablauf zu realisieren.
                Hierbei kommt es meist nicht nur auf die Sicherstellung der
                bestimmten Randbedingungen in Hinsicht auf den
                Produktionsprozess an. Häufig ist auch die Einhaltung des
                Arbeitsschutzes und des Umweltschutzes in Hinsicht auf
                Luftreinheit, Temperatur und Schall von großer Bedeutung.
              </p>
              
              <div class="row">
                <div class="col-md-6 col-sm-12">                  
                <h5 class="rotSweiss fw-bolder">VDMA-Richtlinien</h5>
                  <ul class="ps-3">
                    <li>Abluftanlagen / Maschinenabsaugung</li>
                    <li>Ölnebelabsaugung</li>
                    <li>Feststoffabsaugung</li>
                    <li>Entstaubungsanlagen</li>
                    <li>Laborabzüge</li>
                    <li>Schweißrauchabsaugung</li>
                    <li>Laserabsaugung</li>
                    <li>Be- und Entfeuchtung</li>
                    <li>Luftreinigungssysteme</li>
                  </ul>
                </div>
                <div class="col-md-6 col-sm-12  "><img
                          src="../assets/il.jpg"
                          class="img-fluid mx-auto d-block  imageToBig"
                      @click="showImage(21)"
                        /></div>
              </div> 

              <h3 class="rotSweiss fw-bolder mt-4">Raumlufttechnische Anlagen</h3>
              <div class="row">
                <div class="col-md-6 ps-0">
                  <img src="../assets/ra.jpg" 
                          class="img-fluid mx-auto d-block  pt-4 pb-4  imageToBig"
                      @click="showImage(22)" /></div>
                <div class="col-md-6 pt-4">
                  <p>
                    In modernen Gebäuden mit komplexer Nutzung ist die
                    Gebäudetechnik von enormer Bedeutung. Insbesondere die Luft- und
                    Klimatechnik sichert die Rahmenbedingungen für optimale Lebens-,
                    Arbeits- und Produktionsverhältnisse. RLT- und Klimaanlagen in
                    Gebäuden sorgen für Behaglichkeit im Büro, für Sauberkeit im
                    Fertigungsprozess, stabile Temperaturbedingungen bei thermischen
                    Lasten oder einfach für „frische Luft“ in Besprechungs- oder
                    Konferenzräumen.
                  </p>
              </div>
              </div>
              
              <p>
                Durch moderne Technik ist es heute möglich die hohen
                Anforderungen mit geringstem Energieaufwand und hoher Effizienz
                umzusetzen. Der Einsatz von Wärmerückgewinnungssystemen mit
                hohem Wirkungsgrad spielt hierbei neben der Verwendung von
                strömungsoptimierten Gerätegehäusen, Ventilatoren,
                Schalldämpfern und Filtern eine wichtige Rolle. In Hinsicht auf
                einen geringen Energiebedarf und ökonomischen Betrieb planen wir
                unsere RLT-Anlagen und Systeme unter Einhaltung der Anforderung
                der DIN EN 13779, der VDI 6022, der VDI3803 und der EnEV 2009.
              </p>

              <h5 class="rotSweiss fw-bold pt-4">Reinraumanlagen</h5>
              <ul class>
                <li>GMP</li>
              </ul>
              <h5 class="rotSweiss fw-bold">Messraumklimatisierung</h5>
              <p>
                  In Messräumen sollen Messaufgaben erfüllt werden, die mit
                  minimalsten Ergebnisunterschieden reproduzierbare
                  Messergebnisse abliefern können. Neben der bauphysikalischen
                  Gestaltung des Messraumes ist eine leistungsstarke und
                  hochpräzise Klimaanlage grundlegende Voraussetzung zur
                  Bereitstellung der erforderlichen Rahmenbedingungen. Es kann
                  erforderlich sein, ein Schleusensystem mit Druckkaskade zu
                  installieren. Zur Gewährleistung geringer Spreizungen von
                  Temperatur und Strömungsgeschwindigkeit im Raum ist die
                  Erzeugung laminarer Luftströmung erforderlich. Die Einhaltung
                  der Anforderungen an Luftfeuchtigkeit und Luftreinheit
                  erfordern eine hohe Qualität der Baugruppen und
                  MSR-Einrichtungen. Wir bieten Ihnen das gesamte Leistungsfeld
                  von der Erarbeitung einer Konzeption bis zur Umsetzung an.
                </p>

              <h5 class="rotSweiss fw-bold">Klimaanlagen</h5>
                  <p>
                    „Eine Klimaanlage ist eine Anlage zur Erzeugung und
                    Aufrechterhaltung eines gleichmäßigen Raumklimas. Der
                    Begriff Klimaanlage wird in Deutschland in dem Sinne
                    verwendet, dass in einem Raum Temperatur, Feuchtigkeit und
                    Luftqualität erzeugt und gehalten werden kann. ...“ Quelle
                    Wikipedia . Ein weiterer wichtiger Aspekt ist der
                    hygienische Zustand der Raumluft. Das technische Regelwerk
                    dazu ist in der VDI 6022 definiert.
                  </p>
                  <p>
                    Die Begriffsdefinition „Klimaanlage“ lässt erkennen, dass es
                    sich hierbei nicht nur um die „Kühlung“ eines Raumes
                    handelt. Sie umfasst das gesamte Spektrum der
                    Luftbehandlungsfunktionen von der Filtration über die Be-
                    und Entfeuchtung bis zur Einhaltung eines definierten
                    Temperaturfensters. Es kann sich hierbei um die Kühlung
                    eines Serverraumes oder eines Schaltraumes handeln oder aber
                    eines Laborbereiches. Die Klima- oder Teilklimaanlage kann
                    für einen Konferenzraum oder für eine Schaltwarte vorgesehen
                    sein. Das Anwendungsfeld ist sehr breit.
                  </p>

                  <p>In der DIN EN ISO 7730</p>
                  <p>
                    Wir wählen für den jeweiligen Anwendungsfall die optimale
                    Lösung. Ob Splitgerät, Multisplitgerät, Zentrallüftungsgerät
                    mit Kaltwassererzeuger, freie Kühlung oder
                    Brunnenwasserkühlsystem.
                  </p>
              <h5 class="rotSweiss fw-bold pb-2 pt-4">Wärmerückgewinnungssysteme</h5>
              <br/>
              <h5 class="rotSweiss fw-bold">Allgemeine Raumlufttechnik</h5>
            </div>
          </div>
          
          <div class="row pb-5 pt-5 mb-0 m-0 grauDunkelfeld justify-content-center" id="t4">  
            <div class="col-sm-10 col-md-8">
              <h1 class="rotSweiss fw-bolder pb-4">Produkte</h1>
              <div class="row">
                <div class="col-md-6 col-sm-12 pt-2 ps-0">
                  <p>
                    Die eigene Konstruktion und Fertigung erlaubt es uns durch
                    hohe Kenntnis der technologischen Mittel und Verfahren
                    kundenspezifische Komponenten, Bauteile oder Baugruppen
                    herzustellen. 
                  </p>
                  <p>
                    Wir sind in der Lage mit größtmöglicher
                    Flexibilität Produkte anzubieten, die auf die sehr
                    unterschiedlichen Anforderungen unserer Kunden zugeschnitten
                    sind. 
                  </p>
                  <p>
                    Durch unsere langjährige Erfahrung unserer gut
                    ausgebildeten Mittarbeiter im Bereich der Metall- und
                    Blechverarbeitung können wir auch komplizierte Bauteile in
                    bester Qualität herstellen. Durch eine eigene Konstruktion
                    und Fertigungsvorbereitung wird ein reproduzierbares
                    Qualitätsniveau sichergestellt. 
                  </p>
                </div>
                <div class="col-md-6 col-sm-12">
                  <carouselWrapProdukte></carouselWrapProdukte>
                 </div>
              </div>
              
              
              
              <p>Bei neu entwickelten,
                    komplexen Bauteilen halten wir den ständigen Kontakt zu
                    unserem Kunden. Ziel ist es dabei auftretende Probleme
                    während des Fertigungsprozesses zu erkennen und eventuelle
                    Konstruktionsmängel die in das Produkt einfließen könnten zu
                    vermeiden. </p>
                    <p>Die Einhaltung der vereinbarten Termine sind
                    dabei immer unser Anspruch.
          <ul class="ps-3 pt-5">
            <li>
          Blechverarbeitung auf modernen CNC – gesteuerten Umform- und Schneidmaschinen
            </li>
            <li>
          Herstellung von Blech- und Schweißbauteilen nach Kundenwunsch,
          als Unikat oder in Kleinserie
            </li>
            <li>
              Sonderbauteile zur Erfassung oder Verteilung der Luft wie Hauben, Verteilerkästen, Kappen, Absperreinrichtungen, Saugdüsen, Abscheiderkästen- und Behälter
            </li>
            <li>
              Filterkästen vom Standartfilter über Schwebstofffilter bis zum Aktivkohlefilter
            </li>
            <li>
              Industrieschalldämpfer
            </li>
            <li>
              Musterbau
            </li>
            <li>
              Medienschächte, Medienkanäle und Mediensäulen
            </li>
            <li>
              Wetterschutzgitter für Einbau in Wänden, in schrägen Dachflächen oder für horizontale Montage    
            </li>
            <li>
            Wetterschutzgitter für Einbau in Wänden, in schrägen Dachflächen oder für horizontale Montage
            </li>
            <li>
              Jalousieklappen in beliebigen Maßreihen
            </li>
            <li>
              Zyklonabscheider und Tropfenabscheider
            </li>
            <li>
              Fortluft- und Zulufthauben 
            </li>
            <li>
              Fortluft- und Zulufttürme
            </li>
            <li>
              Sprühnebelabscheider
            </li>
            <li>
              Wärmetauschersysteme zur Wärmerückgewinnung
            </li>
            <li>
              Lüftungskanäle und Formstücke aus verzinktem Stahlblech, Edelstahl oder Aluminium
            </li>
          </ul>
     
                  </p>
            </div>
          </div>
          <div class="row pb-5 pt-5 mb-0 m-0 bg-center justify-content-center" id="t5">
            <div class="col-sm-10 col-md-8 ">
              <h1 class="rotSgrau fw-bolder pb-4">Service</h1>
              <h3 class="rotSweiss fw-bolder">
                 Dichtheitsprobe nach DIN EN 13779</h3>
              <div class="row">
                <div class="col-md-7 col-sm-12 pt-4 ps-0">
                  <p>
                    RLT- und Klimaanlagen haben oft ein weit verzweigtes System
                    an luftführenden Kanälen und Rohrleitungen in denen
                    zusätzlich eine ganze Reihe von unterschiedlichen Bauteilen
                    installiert ist. Durch Fehler bei der Herstellung und bei
                    der Montage kann es zu Undichtheiten kommen, die sich auf
                    fatale Weise bemerkbar machen. Ist die Summe der
                    Leckluftstellen (Leckluftrate) zu hoch wird beim
                    pneumatischen Abgleich der Anlage festgestellt das geplante
                    Luftmengenparameter in einzelnen Bereichen nicht erreicht
                    werden können. Die Folge unzureichende Funktion oder
                    hygienische Problme (VDI6022) durch Eintrag von Bakterien
                    und Stäuben. Ein erhöhter Energieaufwand auf Grund zu hoher
                    Volumenströme der Ventilatoren verursacht zusätzliche
                    Betriebskosten und belastet die Umwelt.
                  </p>
                </div>
                <div class="col-md-5 col-sm-12"><img
                          src="../assets/aussen.jpg"                          
                          class="img-fluid mx-auto d-block p-4  imageToBig"
                      @click="showImage(23)"
                        /></div>
              </div>
              
                  <p>
                    Nach DIN EN 18379 - 3.2.7.1 müssen alle Verbindungen der
                    Luftleitungen entsprechend den Betriebsbedingungen luftdicht
                    und stabil sein. Nach EURUVENT 2/2 und DIN EN 13779 werden
                    die zulässigen Leckluftraten definiert.
                  </p>
                  <p>
                    Wir führen für Sie die Druckprüfung durch, dokumentieren
                    diese und werten die Ergebnisse gemeinsam mit Ihnen aus, um
                    eventuell erforderliche Maßnahmen abzuleiten.
                  </p>
                  <p></p>

                  <h3 class="rotSweiss fw-bolder pt-4 pb-3">
                    Wartung und Reparatur von technischen Anlagen und
                    Einrichtungen
                  </h3>
                  <p>
                    Lüftungs- und Klimaanlagen sind technische Einrichtungen
                    deren dauerhafte Betriebssicherheit durch eine regelmäßige
                    Wartung gewährleistet werden muss. Hierbei gilt es eine
                    Reihe von Dingen zu beachten, die nicht nur für die Funktion
                    der Anlage von Bedeutung sind, sondern auch für die
                    Sicherheit und die hygienische Unbedenklichkeit.
                  </p>
                  <p>
                    In Lüftungs- und Klimaanlagen befinden sich Teile wie
                    Ventilatoren, Verdichter, Antriebe, Pumpen, Wärmetauscher
                    die einem normalen Verschleiß unterliegen. Hinzu kommt oft,
                    dass sich diese Anlagen durch ein komplexes Zusammenspiel
                    der einzelnen Elemente, Bauteile und Baugruppen auszeichnen.
                    Dieses Zusammenspiel wird durch die entsprechende
                    MSR-Technik gesteuert und geregelt. Im Weiteren gibt es
                    Sicherheitseinrichtungen wie Brand- und Rauchschutzklappen
                    sowie Rauchmelder deren regelmäßige Wartung zwingend
                    vorgeschrieben ist und nachgewiesen werden muss, da diese am
                    anderen Fall ihre Zulassung verlieren. Hygienische
                    Untersuchungen nach VDI6022 bieten die Grundlage dafür, dass
                    von diesen Anlagen keine gesundheitlichen Gefährdungen
                    ausgehen.
                  </p>
                  <p>
                    Unsere geschulten Wartungsmonteure betreuen Ihre Anlagen
                    zuverlässig und mit großer Sorgfalt. Die Wartungsarbeiten
                    werden nach VDMA 24186 Teil 1 bis 6 durchgeführt und
                    dokumentiert. Herstellerspezifische Wartungsanforderungen
                    werden dabei ebenso berücksichtigt wie Anforderungen aus
                    Zulassungen oder Prüfzertifikaten.
                  </p>
                  <p>
                    Wird bei der Durchführung der Wartungsarbeiten ein Mangel,
                    ein Defekt oder ein Schaden festgestellt, informieren wir
                    Sie umgehend, so dass über die weitere Vorgehensweise
                    entschieden werden kann. Bei Bedarf erhalten Sie von uns ein
                    kurzfristiges Reparaturangebot zu fairen Preisen. Wir
                    beraten Sie gern über Maßnahmen zur Gewährleistung einer
                    hohen Betriebssicherheit von sensibler Anlagentechnik. Dies
                    beginnt bei der Vorhaltung von Ersatzteilen, der Beurteilung
                    deren durchschnittlicher Lieferzeit über die Einrichtung
                    eines Redundanzbetriebes bis zur planmäßig vorbeugenden
                    Instandhaltung. Durch intelligent geplante Wartungen lässt
                    sich das Ausfallrisiko deutlich reduzieren und erhebliche
                    Kosten einsparen.
                  </p>

                  <h3 class="rotSweiss fw-bolder  pt-4 pb-3">Lufttechnik Hygieneinspektion nach VDI6022</h3>
                  <p>
                    Die VDI6022 „Hygiene-Anforderungen an Raumlufttechnische
                    Anlagen und Geräte“ enthält nicht nur konkrete Anforderungen
                    die bei der Planung und Installation einer RLT-Anlage zu
                    berücksichtigen sind. Sie stellt auch das Regelwerk dar, auf
                    dessen Basis der Betreiber einer RLT-Anlage
                    eigenverantwortlich verpflichtet ist die Erfüllung dieser
                    Anforderungen dauerhaft sicherzustellen.
                  </p>
                  <p>
                    Bereits im November 1985 wurde in der „Kröling-Studie“
                    festgestellt welchen Einfluss der hygienische Zustand einer
                    Klimaanlage auf das Befinden und die Gesundheit des Menschen
                    haben kann. Mit dem so genannten „sick building syndrom“
                    werden Krankeitssymptome wie häufige Infekte, Juckreiz,
                    Kopfschmerzen Übelkeit u.ä. beschrieben. Diese können aus
                    Baumaterialien, Möbeln, Bodenbelägen und anderen
                    Einrichtungsgegenständen herrühren. Die Ursache kann aber
                    auch einer mangelhaften RLT- oder Klimaanlage darstellen.
                    Damals kam die gesamte Branche als „Krankmacher“ in Verruf.
                    Auch im Ergebnis dessen wurde die VDI6022 eingeführt. Sie
                    schafft die Voraussetzung zum Erreichen der in der
                    Arbeitsstättenverordnung §3 ; §3a und §4 (ArbStättV), dem
                    Arbeitsschutzgesetz (ArbSchG) §3 und §4 und der
                    Arbeitsstättenrichtlinie (ASR 5) formulierten Ziele.
                  </p>
                  <p>
                    Wir führen die Hygieneinspektionen nach VDI6022 durch. Von
                    der Erstinspektion mit der dazugehörigen Bewertung über die
                    mikrobiologische Untersuchung bis zur Auswertung bieten wir
                    Ihnen die komplette Leistung. Im Rahmen einer Wartung wird
                    von unseren geschulten und zertifizierten Mittarbeitern, die
                    wiederkehrenden Hygieneinspektion durchgeführt. Sie erhalten
                    von uns die anforderungsgerechte Dokumentation zum Nachweis.
                  </p>

            </div>
          </div>
        </div>
      </div>
    </template>
  </Layout>
</template>

<script>
import Layout from "@/layouts/Layout";
import carouselWrapProdukte from "../components/carouselWrapProdukte.vue";
import { openModal } from "jenesius-vue-modal";
import ModalsImageDisplay from "../components/ModalsImageDisplay.vue";
//import carouselHome from "../components/carouselHome.vue";

export default {
  name: "Leistungen",

  data() {
    return {
      newSite: null,
    };
  },
  components: {
    Layout,
    carouselWrapProdukte,
   // carouselHome,
  },
  methods: {
   async showImage(Imagenumber){
        const modal= await openModal(ModalsImageDisplay,{Imagenumber:Imagenumber});
        modal.onclose = () => {
        console.log("Close Modal gefunden");
        

        return true; //Modal will not be closed
      };
    },},
  computed: {},
};
</script>

<style scoped></style>
