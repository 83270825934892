<template>
  <Layout>
    <template #default>
      <div>
        <div class="container-fluid p-0 titelBackground">
          <div class="row pb-5 pt-3">
            <div class="col-sm-10 col-md-3 p-0">
              <img
                src="../assets/chemnitz-tlogo_weiss.png"
                class="img-fluid pt-3  slInLeft"
                alt="..."
              />
            </div>
          </div>
          <div class="row pb-5 pt-5 mb-5 justify-content-center " style="height: 700px;">
            <div class="col-md-4 ">
              <div class="bgWeiss15 mt-5 ">
                <h1 class="h1Grau mb-0 p-0 fw-bold text-center ele">
                  TECHNOLOGISCH
                </h1>
                <h1 class="h1Grau mt-0 p-0 mb-0 fw-bold text-center ele">
                  INDIVIDUELL
                </h1>
                <h1 class="h1Grau p-0 fw-bold text-center ele pb-3">FLEXIBEL</h1>
                <h3 class="h2Rot fw-bold ele" style="text-align: center">
                  PROJEKTIERUNG, PRODUKTION,<br/> DIENSTLEISTUNG
                </h3>
              </div>
            </div>
          </div>
          

          <div class="row pb-2 ps-0 mb-0 bg-main">
            <div class="col-md-12 p-0">

               <div class="row weissSschwarz pt-5  justify-content-center">
                <div class="col-12 h2Rot fw-bold text-center">
                  <h2 >Ihr Projekt in guten Händen</h2>
                </div>
              </div>
              <div class="row weissSschwarz justify-content-center">
                <div
                  class="col-sm-10 col-md-4 pt-5  text-center"
                >
                  <p >
                    Von der Idee, bis zum fertigen Produkt, ist Ihr Projekt bei
                    uns in guten Händen. Gern stehen wir Ihnen als zuverlässiger
                    und kompenter Partner im Bereich Metall- bzw. Lüftungsbau
                    zur Seite.
                  </p>
                </div>
              </div>
              <div class="row weissSschwarz justify-content-center">
                <div
                  class="col-sm-10 col-md-5  p-2 mt-4 bg-white text-center"
                >
                  <router-link to="/Kontakt" style="text-decoration: none"
                    ><h3 class="h2Rot pt-2 pb-5">
                      Zur unverbindlichen Projektanfrage
                      <i class="fas fa-long-arrow-alt-right"></i></h3
                  ></router-link>
                </div>
              </div>


              <div class="row justify-content-center">
                <div class="col-sm-10 col-md-4  pt-5 h1Weiss text-center">
                  <h2 class="mb-5">Unternehmen / Philosophie</h2>
                  <p>
                    Unser Ziel ist Qualität und Nachhaltigkeit.
                    Kundenpartnerschaft und Kooperation ist für uns die Basis
                    der geschäftlichen Beziehung.
                  </p>
                  <p>
                    Sie bekommen bei uns Lösungen die genau auf Ihre
                    Anforderungen und Wünsche zugeschnitten sind. Wir verknüpfen
                    dabei jahrelange Erfahrung mit modernster Technologie.
                  </p>
                  <p>
                    So fertigen wir auf CNC-gesteuerten Schneid- und
                    Biegemaschinen und schweißen mit moderner Schweißtechnik
                    Stahl- Edelstahl- sowie Aluminiumwerkstoffe.
                  </p>
                </div>
               <div class="row">
                  <div class="col-12 pt-3 text-center mb-0" >
                    <a class="btn feldrot" @click="openGeschichte()"><h3>Unternehmensgeschichte <i class="fas fa-long-arrow-alt-right"></i></h3></a>                     
                  </div>
                </div>
              </div>




            </div>
          </div>

          <div class="row pt-5 mb-0 bg-center">
            <div class="col-md-12">
              <div class="row">
                <div class="col-12 fw-bold text-center">
                  <h2 class="rot">Unsere Leistungen</h2>
                </div>
              </div>
              <div class="row justify-content-center">
                <div
                  class="col-sm-10  col-md-4  pt-5 text-center"
                >
                  <p>
                    Im Folgenden können Sie sich einen Überblick über unser
                    vielfältiges Leistungsspektrum verschaffen. Gern
                    unterstützen wir Sie darüber hinaus bei projektbezogene,
                    individuellen Tätigkeiten. Nutzen Sie hierfür unser
                    Kontaktformular.
                  </p>
                </div>
              </div>
              <div class="row mt-5 d-none d-lg-block">
                <div class="col-md-12">
                  <carouselHome></carouselHome>
                </div>
              </div>
            </div>
          </div>
          <div class="row pt-5 mb-0 grauDunkelfeld">
            <div class="col-md-12">
              <div class="row">
                <div class="col-12 pb-4 fw-bold text-center">
                  <h2>Unsere Referenzen</h2>
                </div>
              </div>
              <div class="row justify-content-center">
                <div
                  class="col-sm-10  col-md-5  mt-3 mb-5 text-center"
                >
                  <p>
                    Erfolgreich abgeschlossene Projekte können Sie sich im
                    Folgenden ansehen.
                  </p>
                </div>
              </div>
              <div class="row mb-5 justify-content-center">
                <div class="col-md-8 ">
                  <div class="row">
                    <div class="col-md-6">
                      <router-link to="/AktuellesRef" class="zubRouterLink"
                        ><img
                          src="../assets/ref1.jpg"
                          class="img-fluid mx-auto d-block"
                          style="height: 93%"
                          alt="..."
                        />
                        <div class="row zub">
                          <div
                            class="col-7 zubInnen"
                            @mouseenter="addClass"
                            @mouseleave="removeClass"
                          >
                            TITK Rudolstadt
                            <img
                              src="../assets/pfeil_rechts.png"
                              class="img-fluid pfeil_rechts"
                              alt="..."
                            />
                          </div>
                        </div>
                      </router-link>
                    </div>
                    <div class="col-md-6 align-self-center">
                      <router-link to="/Kontakt" class="zubRouterLink"
                        ><img
                          src="../assets/ref2.jpg"
                          class="img-fluid mx-auto d-block"
                          alt="..."
                        />
                        <div class="row zub">
                          <div
                            class="col-7 zubInnen"
                            @mouseenter="addClass"
                            @mouseleave="removeClass"
                          >
                            Produkte
                            <img
                              src="../assets/pfeil_rechts.png"
                              class="img-fluid pfeil_rechts"
                              alt="..."
                            />
                          </div>
                        </div>
                      </router-link>
                      <router-link to="/AktuellesRef#ref-4" class="zubRouterLink"
                        ><img
                          src="../assets/ref3.jpg"
                          class="img-fluid mx-auto d-block"
                          alt="..."
                        />
                        <div class="row zub">
                          <div
                            class="col-7 zubInnen"
                            @mouseenter="addClass"
                            @mouseleave="removeClass"
                          >
                            Universität Jena
                            <img
                              src="../assets/pfeil_rechts.png"
                              class="img-fluid pfeil_rechts"
                              alt="..."
                            />
                          </div>
                        </div>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row"></div>
        </div>
      </div>
    </template>
  </Layout>
</template>

<script>
<link rel="stylesheet" href="https://unpkg.com/vue-agile/dist/VueAgile.css" />;
import Layout from "@/layouts/Layout";
import carouselHome from "../components/carouselHome.vue";
import modalGeschichte from "../components/Geschichte.vue";
import { openModal } from "jenesius-vue-modal";

export default {
  name: "Home",
  data() {
    return {
      newSite: null,
      zuBInnen: "zubInnen",
      zuBInnenOver: "zubInnenOver",
    };
  },
  components: {
    Layout,
    carouselHome,
  },
  methods: {
    addClass(e) {
      console.log("innen");
      e.target.classList.remove("zubInnen");
      e.target.classList.add("zubInnenOver");
    },
    removeClass(e) {
      console.log("außen");
      e.target.classList.remove("zubInnenOver");
      e.target.classList.add("zubInnen");
    },
    openGeschichte() {
      openModal(modalGeschichte);
    },
  },
  computed: {},
};
</script>

<style scoped></style>
